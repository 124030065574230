import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-alert-id-message',
  templateUrl: 'alert-id-message.component.html',
  styleUrls: ['./alerts-id-message.component.scss']
})
export class AlertIdMessageComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AlertIdMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any)
  { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
