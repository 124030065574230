import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Alert } from 'src/app/services/entities/alert';
import { onAlertDetailClosed, onAlertDetailLoad } from './alert-detail.events';
import { onClickMarginTop } from 'src/app/pages/page-events';
import { URL_ASSETS, URL_API } from 'src/environments/environment';
@Component({
  selector: 'app-alert-detail',
  templateUrl: './alert-detail.component.html',
  styleUrls: ['./alert-detail.component.scss']
})
export class AlertDetailComponent implements  OnInit {

  alert: Alert = new Alert();
  alert_id: number = null
  URL_img_before:string = null
  URL_img_after:string = null
  imgUndefined:boolean = false
  URL_ASSETS = URL_ASSETS;
  ENDPOINT_API = URL_API
  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
  ) { }

  statusHeader = 'CLOSE';
  ngOnInit():void {
    const statusPanel = localStorage.getItem('panelExpansion')
    this.statusHeader = statusPanel ? statusPanel : 'CLOSE';
    console.log('right route: ', this.activatedRoute)
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.alert_id = params.id;
        this.loadAlert(params.id);
      }
    });

    onClickMarginTop.subscribe((action) => {
      this.statusHeader = action;
    })
  }

  async loadAlert(id: number) {
    try {
      this.alert    = await this.apiService.getAlertById(id).toPromise();
      this.alert_id = id;
      if (this.alert.source == "Mapbiomas") {
        this.URL_img_before = `https://storage.googleapis.com/alerta-public/IMAGES/${this.alert.original_id}/before_deforestation.png`
        this.URL_img_after  = `https://storage.googleapis.com/alerta-public/IMAGES/${this.alert.original_id}/after_deforestation.png`
      } else if (this.alert.source != "SMDK") {
        this.URL_img_before = `${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_before.split(',')[0]}_antes_poly.png`
        this.URL_img_after  = `${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_after.split(',')[0]}_depois_poly.png`
      } else {
        this.URL_img_before = `${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_before.split(',')[0]}_poly.png`
        this.URL_img_after  = `${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_after.split(',')[0]}_poly.png`
      }

      onAlertDetailLoad.emit(this.alert);
    } catch (error) {
      console.log(error)
      this.imgUndefined = !error.ok

    }

    let imgBefore = new Image();
    imgBefore.src = this.URL_img_before;
    imgBefore.onerror = () => {
      this.imgUndefined = true
    }
    imgBefore.onload = () => {
      this.imgUndefined = false
    }
  }

  handleGoBackHome() {
    this.route.navigate(['home']);
    onAlertDetailClosed.emit(this.alert);
  }
}
