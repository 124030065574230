
import { URL_FILE } from 'src/environments/environment';

export const OVERLAYERS = [
    {
        camada: "CAR",
        layerKey: "CAR",
        fonte: "SICAR",
        ano: "2021",
        url: "https://www.car.gov.br/publico/municipios/downloads?sigla=RO"
    },
    {
        camada: "UC",
        layerKey: "UC",
        fonte: "MMA",
        ano: "2019",
        url: "http://mapas.mma.gov.br/i3geo/datadownload.htm"
    },
    {
        camada: "Autorizações",
        layerKey: "AUTORIZACOES",
        fonte: "SISCOM",
        ano: "2021",
        url: "http://siscom.ibama.gov.br/geoserver/publica/ows?service=WFS&version=1.0.0&request=GetFeature&typename=publica:vw_asv_aasv_sinaflor_a&outputFormat=SHAPE-ZIP"
    },
    {
        camada: "Embargos",
        layerKey: "EMBARGOS",
        fonte: "SISCOM",
        ano: "2021",
        url: "http://siscom.ibama.gov.br/geoserver/publica/ows?service=WFS&version=1.0.0&request=GetFeature&typename=publica:vw_brasil_adm_embargo_a&outputFormat=SHAPE-ZIP"
    },
    {
        camada: "Municípios",
        layerKey: "Municipios",
        fonte: "IBGE",
        ano: "2020",
        url: "https://geoftp.ibge.gov.br/organizacao_do_territorio/malhas_territoriais/malhas_municipais/municipio_2020/UFs/RO/RO_Municipios_2020.zip"
    },
    {
        camada: "Quilombos",
        layerKey: "Quilombos",
        fonte: "INCRA",
        ano: "2021",
        url: "https://certificacao.incra.gov.br/csv_shp/zip/%C3%81reas%20de%20Quilombolas_RO.zip"
    },
    {
        camada: "TI",
        layerKey: "TI",
        fonte: "FUNAI",
        ano: "2021",
        url: "https://www.gov.br/funai/pt-br/atuacao/terras-indigenas/geoprocessamento-e-mapas"
    },
    {
        camada: "TI - Buffer 3km",
        layerKey: "TIb3",
        fonte: "FUNAI",
        ano: "2021",
        url:`${URL_FILE}/download/kaninde/view_ti_buf3.zip`
    },
    {
        camada: "TI - Buffer 5km",
        layerKey: "TIb5",
        fonte: "FUNAI",
        ano: "2021",
        url:`${URL_FILE}/download/kaninde/view_ti_buf5.zip`
    },
    {
        camada: "TI - Buffer 10km",
        layerKey: "TIb10",
        fonte: "FUNAI",
        ano: "2021",
        url:`${URL_FILE}/download/kaninde/view_ti_buf10.zip`
    },
    {
        camada: "Máscara PRODES",
        layerKey: "PRODES",
        fonte: "INPE",
        ano: "2021",
        url: "http://terrabrasilis.dpi.inpe.br/downloads/"
    },
    {
        camada: "Assentamentos",
        layerKey: "ASSENTAMENTOS",
        fonte: "INCRA",
        ano: "2021",
        url: "https://certificacao.incra.gov.br/csv_shp/zip/Assentamento%20Brasil_RO.zip"
    },
    {
        camada: "Estradas",
        layerKey: "RODOVIAS",
        fonte: "SIPAM",
        ano: "2010",
        url: "https://metadados.inde.gov.br/geonetwork/srv/por/catalog.search#/metadata/0479c5d6-da4b-4a58-988b-02de3a4f1661"
    },
    {
        camada: "Etnozoneamento",
        layerKey: "ETNOZONEAMENTO",
        fonte: "Kanindé",
        ano: "2021",
        url: `${URL_FILE}/download/etnozoneamento/etnozoneamento.zip`
    },
    {
        camada: "Aldeias",
        layerKey: "ALDEIAS",
        fonte: "Kanindé",
        ano: "2021",
        url: `${URL_FILE}/download/aldeias/aldeias.zip`
    },
    {
        camada: "SRTM Relevo",
        layerKey: "SRTM",
        fonte: "SRTM",
        ano: "2021",
        url: 'https://www.terrestris.de/de/topographic-wms/'
    },
    {
        camada: "Drenagem - BHO2017",
        layerKey: "ANA",
        fonte: "ANA",
        ano: "2017",
        url: `https://metadados.snirh.gov.br/files/f7b1fc91-f5bc-4d0d-9f4f-f4e5061e5d8f/geoft_bho_2017_5k_trecho_drenagem.gpkg`
    },
    {
        camada: "FIRMS - 7 dias",
        layerKey: "FIRMS",
        fonte: "NASA",
        ano: "2022",
        url: `${URL_FILE}/download/firms/firms_7d.zip`
    },
    {
        camada: "Pistas de Pouso",
        layerKey: "PISTAS",
        fonte: "MapBiomas",
        ano: "2021",
        url: `http://kaninde.solved.eco.br:8080/geoserver/kanindeRO/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=kanindeRO%3Ageo_pista_pouso&maxFeatures=50&outputFormat=SHAPE-ZIP`
    },
];
