<section class="c-box-content-login">
  <section class="c-box-header-login">
    <span class="c-box-header-login__title">Entrar na plataforma</span>
    <span class="c-box-header-login__sub-title">Acesse sua conta</span>
  </section>

  <section class="c-box-form">
    <form (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="Informe seu e-mail" [(ngModel)]="loginData.email" [ngModelOptions]="{standalone: true}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input type="password" matInput placeholder="Informe sua senha" [(ngModel)]="loginData.password" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-error *ngIf="showLoginError" style="margin-bottom: 5px;">Dados informados estão incorretos</mat-error>

      <section class="c-box-header-password">
        <span (click)="openDialog('RecoverPasswordComponent', 478)">Esqueceu a senha ?</span>
      </section>
      <button mat-raised-button class="c-box-content-login__btn">Entrar</button>
    </form>
  </section>
</section>
