import { EventEmitter } from "@angular/core";

export const onMapLoadData = new EventEmitter<{
    it: any
}>();

export const onMapFilterDateChange = new EventEmitter<any>();

export const onMapBaseLayerChange = new EventEmitter<string>();

export const onMapReferenceLayerChange = new EventEmitter<any>();

export const onUserCoordinatesChange = new EventEmitter<any>();

export const onMapInspectorClick = new EventEmitter<any>();

export const onMapInspectorClosed = new EventEmitter<any>();
