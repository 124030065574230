import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-deforestation-maskComponent',
  templateUrl: './deforestation-mask.component.html',
  styleUrls: ['./deforestation-mask.component.scss']
})
export class DeforestationMaskComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DeforestationMaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [])
  { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
