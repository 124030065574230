import { Component, OnInit, Renderer2, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { onMapBaseLayerChange } from '../map.events';
@Component({
  selector: 'app-baselayer',
  templateUrl: './baselayer.component.html',
  styleUrls: ['./baselayer.component.scss']
})
export class BaseLayerComponent implements OnInit {
  @ViewChild('baseLayerPanel') baseLayerPanel: ElementRef;
  @Input() statusPanel : boolean;
  @Output() openLayerEvent = new EventEmitter<string>();
  el: ElementRef;

  baseLayerKey: string = 'Satellite';

  constructor(private renderer: Renderer2,el: ElementRef) {this.el = el;}

  ngOnInit(): void {
    let localStorageLAyer = localStorage.getItem('baseLayer');
    if (localStorageLAyer) {
      this.baseLayerKey = localStorageLAyer;
    } else {
      localStorage.setItem('baseLayer', this.baseLayerKey);
    }
  }

  layerSelected(event) {
    onMapBaseLayerChange.emit(event.value);
    localStorage.setItem('baseLayer', this.baseLayerKey);
  }

  close() {
    this.openLayerEvent.emit('base');
  }

  ngAfterViewInit() {
    const hostElem = this.el.nativeElement;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.statusPanel == true && !hostElem.parentNode.contains(e.target) && !this.baseLayerPanel.nativeElement.contains(e.target)) {
        this.close();
      }
    });
  }
}
