<!-- <div class="c-map-statistic" [ngStyle]="statusHeader === 'CLOSE' ? {'top': '120px' } : {'top': '260px'}"> -->
<div class="c-map-statistic" [ngClass]="statusHeader === 'CLOSE' ? 'c-map-statistic-animate-top' : 'c-map-statistic-animate-bottom'">
  <mat-accordion [ngClass]="[panelExpansionStyle]">
    <mat-expansion-panel
    [ngClass]="statusHeader === 'CLOSE' ? 'custom-dynamic-class-expansion-panel-max' : 'custom-dynamic-class-expansion-panel-min'"
    (opened)="panelExpansionOpened()"
    (afterCollapse)="panelExpansionClosed()"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="c-map-titles c-map-statistic-title">
          Estatísticas
        </mat-panel-title>
      </mat-expansion-panel-header>

      <section class="c-map-statistic-wrapper">
        <div class="c-map-statistic-content">
          <section class="c-map-statistic-content__numbers">
            <div class="numbers_box">
              <span>Total de alertas</span>
              <span>{{totalAlerts}} (und)</span>
            </div>
            <div class="numbers_box">
              <span>Área alertada</span>
              <span>{{totalDeforestedArea}} (ha)</span>
            </div>
          </section>
          <section [formGroup]="toppings">
            <div class="c-map-statistic-graph" >
              <app-chart1></app-chart1>
              <app-chart2></app-chart2>
              <app-chart3></app-chart3>
              <app-chart5></app-chart5>
              <app-chart4></app-chart4>
              <app-chart6></app-chart6>
            </div>
          </section>
        </div>
      </section>
    </mat-expansion-panel>
  </mat-accordion>
</div>
