export class User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  security_level: number;
  deleted_at: string;
  password: string;

  getSecurityLevelLabel() {
    return SecurityLevelLabel[this.security_level];
  }
}

const SecurityLevelLabel = {
  1: 'Registrado',
  2: 'Admin'
};
