<ng-container>
  <a target="_blank" href="https://www.planet.com/nicfi/" matTooltip="
  Dados Planet NICFI, mosaicos mensais.">
    <img class="position-globe" src="/../assets/img/NICFI_icon.svg">
  </a>
  <span id="arrow-left" (click)="previous()">
    <img _ngcontent-ehe-c402="" src="assets/img/validation/arrow.svg" style="width: 10px;">
  </span>
  <ng-container *ngIf="options">
    <ngx-slider id="slider-timeline" [options]="options" [value]="value" (valueChange)="onSliderChange($event)"></ngx-slider>
  </ng-container>
  <span id="arrow-right" (click)="next()">
    <img _ngcontent-ehe-c402="" src="assets/img/validation/arrow.svg" style="width: 10px;">
  </span>
</ng-container>
