import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HeaderLoginComponent } from './header_login/header-login.component';
import { HeaderUserComponent } from './header_user/header-user.component';
import { AboutComponent } from './about/about.component';
import { ShareComponent } from './share/share.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImagesSwapSliderComponent } from './images-swap-slider/images-swap-slider.component';
import { MaterialModule } from './../material.module';
import { RouterModule } from '@angular/router';
import { AlertIdMessageComponent } from './alert-id-message/alert-id-message.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AvatarModule } from 'ngx-avatar';
import { DialogComponent } from './dialog/dialog.component';
import { HeaderUploadComponent } from './header-upload/header-upload.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavToggleButtonComponent } from './sidenav/sidenav-toggle-button/sidenav-toggle-button.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';


@NgModule({
  imports: [
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    CommonModule,
    ShareModule,
    NgSelectModule,
    FormsModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSliderModule,
    MatDialogModule,
    AvatarModule,
    MatListModule,
    MatSidenavModule
  ],
  declarations: [
    HeaderComponent,
    HeaderLoginComponent,
    HeaderUserComponent,
    AboutComponent,
    ShareComponent,
    ImagesSwapSliderComponent,
    DialogComponent,
    AlertIdMessageComponent,
    HeaderUploadComponent,
    SidenavComponent,
    SidenavToggleButtonComponent
  ],
  exports: [
    HeaderLoginComponent,
    HeaderUserComponent,
    HeaderComponent,
    AvatarModule,
    ImagesSwapSliderComponent,
    HeaderUploadComponent,
    SidenavComponent,
    SidenavToggleButtonComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedComponentsModule { }
