import { Component, Input, OnInit} from '@angular/core';
import { FormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { onClickMapZoom } from 'src/app/pages/page-events';
import { SearchLocalizationComponent } from '../search-localization/search-localization.component';
import { onMapFilterDateChange } from '../map.events';
import * as L from 'leaflet';
@Component({
  selector: 'app-btn-controll',
  templateUrl: './btn-controll.component.html',
  styleUrls: ['./btn-controll.component.scss']
})

export class BtnControllComponent implements OnInit {

  @Input()
  map: L.Map;

  modalComponents = {};
  filter = {
    dateStart: new Date('2021-08-02'),
    dateEnd: new Date()
  }

  activeOpenDialogOptionsBaseLayer = false;
  activeOpenDialogOptionsOverLayer = false;
  activeOpenSubtitle = false;
  constructor(public dialog: MatDialog,) {
    this.modalComponents = {
      'SearchLocalizationComponent': SearchLocalizationComponent
    }
  }

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString().substr(0, 10));
  position = {
    bottom: '80px',
    left: '40px'
  }
  ngOnInit() {

  }

  handleZoom(zoom) {
    onClickMapZoom.emit(zoom);
  }

  openDialog(component, width, customPosition = false): void {
    this.activeOpenDialogOptionsOverLayer = false;
    this.activeOpenDialogOptionsBaseLayer = false;
    this.activeOpenSubtitle = false;
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`,
      position: customPosition ? this.position : {}
    });
  }

  openDialogOptionsLayer(optionLayer) {
    if (optionLayer === 'base') {
      this.activeOpenDialogOptionsBaseLayer = !this.activeOpenDialogOptionsBaseLayer;
      this.activeOpenDialogOptionsOverLayer = false;
      this.activeOpenSubtitle = false;
      return;
    }
    if (optionLayer === 'over') {
      this.activeOpenDialogOptionsOverLayer = !this.activeOpenDialogOptionsOverLayer;
      this.activeOpenDialogOptionsBaseLayer = false;
      this.activeOpenSubtitle = false;
    }
  }

  toogleSubtitle() {
    this.activeOpenSubtitle = !this.activeOpenSubtitle
    this.activeOpenDialogOptionsOverLayer = false;
    this.activeOpenDialogOptionsBaseLayer = false;
  }

  dateChange() {
    onMapFilterDateChange.emit(this.filter);
  }

}
