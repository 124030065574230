import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-layout',
  templateUrl: './map-layout.component.html',
  styleUrls: ['./map-layout.component.scss']
})
export class MapLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
