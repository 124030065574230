<section class="c-deforestation-mask">
  <section class="c-deforestation-mask__header">
    <div class="c-deforestation-mask__header__text">
      <span>Mascara de desmatamento</span>
    </div>
    <div class="c-deforestation-mask__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>
  <section class="c-deforestation-mask__content">
  </section>
</section>
