import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/services/entities/user.entity';
import { UsersService } from 'src/app/services/users.service';
import { onUserSaved } from './add-events';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {


  userFormData: UserFormData = {} as UserFormData;
  userFormValidation: any = {};

  serverResponseMsg: string = null;

  constructor(
    public dialogRef: MatDialogRef<AddComponent>,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: { user: User }) {

  }

  ngOnInit(): void {
    if (this.data?.user?.id) {
      this.userFormData = Object.assign(new UserFormData(), this.data.user);
    } else {
      this.userFormData = new UserFormData();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit() {

    const userData = Object.assign(new User(), this.userFormData);

    const [isValid, validation] = this.userFormData.validate();

    this.userFormValidation = validation;

    if (isValid) {

      if (!userData.id) {
        await this.usersService.save(userData).toPromise()
          .then(() => {
            this.userFormData = {} as UserFormData;
            onUserSaved.emit();
            this.dialogRef.close();
          })
          .catch(error => {            
            this.serverResponseMsg = error.error;
            setTimeout(() => {
              this.serverResponseMsg = null;              
            }, 3000);
          });
        } else {
          await this.usersService.update(userData).toPromise()
          .then(() => {
            this.userFormData = {} as UserFormData;
            onUserSaved.emit();
            this.dialogRef.close();
          })
          .catch(error => {
            this.serverResponseMsg = error.error;
            setTimeout(() => {
              this.serverResponseMsg = null;              
            }, 3000);
          });
      }
    }
  }
}


class UserFormData extends User {
  cpassword: string;

  validate() {
    const validation: any = {};
    let isValid = true;

    validation['password'] = this.cpassword == this.password;

    Object.keys(validation).forEach(key => {
      if (validation[key] === false) {
        isValid = false;
      }
    });

    return [isValid, validation];
  }
}