import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
// import { onIsAlertVisualizationDetailOpenedChange } from 'src/app/pages/alerts/validation/alerts-validation-detail/alerts-validation-detail.events';
// import { onIsAlertVisualizationStatisticsOpenedChange } from 'src/app/pages/alerts/visualization/alerts-visualization-statistics/alerts-visualization-statistics.events';
import { ApiService } from 'src/app/services/api.service';
import { onMapControlActivated } from '../controls/map-controls.events';
import { PointInspectorContentComponent } from './point-inspector-content.component';
import { icon, Marker } from 'leaflet';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-point-inspector',
  templateUrl: './point-inspector.component.html',
  styleUrls: [
    '../btn-controll/btn-controll.component.scss',
    './point-inspector.component.scss'
  ],
})
export class PointInspectorComponent implements OnInit, OnDestroy {

  static Events = {
    onIsActivatedChange: new EventEmitter<boolean>(),
    onLoadInfo: new EventEmitter<any>(),
  };

  controlName = 'map-control-point-inspector';

  @Input()
  map: L.Map;

  @Input()
  filter: any;

  isActivated = false;

  alertsVisualizationStatisticsIsOpened = false;

  marker: L.Draw.Marker;

  alertsVisualizationDetailIsOpened: boolean = false;

  onMapControlActivated$: Subscription;
  onIsAlertVisualizationStatisticsOpenedChange$: Subscription;
  onIsAlertVisualizationDetailOpenedChange$: Subscription;
  PointInspectorContentComponent$: Subscription;

  constructor(
    private apiService: ApiService
  ) {

  }

  ngOnInit() {

    L.drawLocal.draw.toolbar.buttons.marker = 'Clique para extrair infomações do mapa.';
    L.drawLocal.draw.handlers.marker.tooltip.start = 'Clique para extrair infomações do mapa.';

    this.marker = new L.Draw.Marker(this.map as any);

    this.onMapControlActivated$ =
      onMapControlActivated.subscribe(control => {
        if (control != this.controlName) {
          this.isActivated = false;
          this.marker.disable();
        }
      });

    // this.onIsAlertVisualizationStatisticsOpenedChange$ =
    //   onIsAlertVisualizationStatisticsOpenedChange.subscribe(isOpened => {
    //     this.alertsVisualizationStatisticsIsOpened = isOpened;
    //   });

    // this.onIsAlertVisualizationDetailOpenedChange$ =
    //   onIsAlertVisualizationDetailOpenedChange.subscribe(isOpened => {
    //     this.alertsVisualizationDetailIsOpened = isOpened;
    //   });

    this.PointInspectorContentComponent$ =
      PointInspectorContentComponent.Events.onStatusChange.subscribe(status => {
        if (status == 'closed') {
          this.isActivated = false;
        }
      });

    this.map.on(L.Draw.Event.CREATED, (event: any) => {
      if (this.isActivated) {
        this.loadInfoByLatLng(event.layer._latlng);
      }
    });

  }

  ngOnDestroy(): void {
    this.onMapControlActivated$.unsubscribe();
    // this.onIsAlertVisualizationStatisticsOpenedChange$.unsubscribe();
    // this.onIsAlertVisualizationDetailOpenedChange$.unsubscribe();
    this.PointInspectorContentComponent$.unsubscribe();
  }

  active() {
    this.isActivated = !this.isActivated;
    PointInspectorComponent.Events.onIsActivatedChange.emit(this.isActivated);
    if (this.isActivated) {
      onMapControlActivated.emit(this.controlName);
      this.drawPoint();
    } else {
      this.marker.disable();
    }

  }

  drawPoint() {

    L.drawLocal.draw.toolbar.buttons.marker = 'Clique para extrair infomações do mapa.';
    L.drawLocal.draw.handlers.marker.tooltip.start = 'Clique para extrair infomações do mapa.';
    this.marker = new L.Draw.Marker(this.map as any);

    this.marker.enable();
  }

  async loadInfoByLatLng(latLng: L.LatLng) {

    const pointInfo = await this.apiService.getPointInfo(latLng);
    const pointHistory = await this.apiService.getPointHistory(latLng);
    const pointFireHistory = await this.apiService.getPointFireHistory(latLng);

    // console.log('responses:', pointInfo, pointHistory, pointFireHistory)

    PointInspectorContentComponent.Events.onLoadInfo.emit({
      lat: latLng?.lat,
      lng: latLng?.lng,
      info: pointInfo,
      history: pointHistory,
      fireHistory: pointFireHistory
    });
  }

  @HostListener('document:keydown.escape')
  handleEscapeKeyDown() {
    this.isActivated = false;
    PointInspectorComponent.Events.onIsActivatedChange.emit(this.isActivated);
    this.marker.disable();
  }
}
