import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-search-localization-dialog',
  templateUrl: './search-localization-dialog.component.html',
  styleUrls: ['./search-localization-dialog.component.scss']
})
export class SearchLocalizationDialogComponent implements OnInit {

  change = new EventEmitter<{
    lat?: number,
    long?: number,
  }>()

  coordsChange = new EventEmitter<string>()

  coordinates: {
    lat?: number,
    long?: number,
  } = {};

  // coords: string = '(-14.326485801236203,-56.99817033972225),(-14.074596073510293,-56.6361811559955),(-14.390577260064319,-56.643589965263544)';
  coords: string;

  constructor(
    public dialogRef: MatDialogRef<SearchLocalizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: []) { }

  ngOnInit(): void {
    const savedCoords = sessionStorage.getItem('app-search-localization');
    if (savedCoords) {
      this.coords = savedCoords;
      this.onCoordinatesChange({});
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCoordinatesChange($event: any) {
    if (this.coordinates.lat && this.coordinates.long) {
      this.change.emit(this.coordinates);
    }

    this.coordsChange.emit(this.coords);

    sessionStorage.setItem('app-search-localization', this.coords);
  }

  onKeyUp($event: any) {
    if ($event.key == 'Escape')
      this.change.emit(null);
    this.coordsChange.emit(null);
  }
}
