<section class="c-recoverpassword">
  <section class="c-recoverpassword__header">
    <div class="c-recoverpassword__header__text">
      <span>Recuperar senha</span>
    </div>
    <div class="c-recoverpassword__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>
  <section class="c-recoverpassword__content">
    <div *ngIf="initialPage">
      <p>
        Preencha o campo abaixo com o e-mail que receberá o link de recuperação de senha.
      </p>
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input type="email" [formControl]="emailFC" [(ngModel)]="changePasswordData.email" matInput
          placeholder="Informe seu e-mail">
      </mat-form-field>

      <mat-error *ngIf="emailFC.hasError('email') && !emailFC.hasError('required')">
        Por favor entre com email válido
      </mat-error>
      <mat-error *ngIf="emailFC.touched && emailFC.hasError('required')">
        Email para recuperação é <strong>obrigatório</strong>
      </mat-error>
      <mat-error *ngIf="errorEmail">
        {{errorEmailMSG}}
      </mat-error>
      <section class="c-recoverpassword__btn">
        <button [disabled]="emailFC.invalid" (click)="emailForPassword()" mat-raised-button
          color="primary">Enviar</button>
      </section>
    </div>

    <div *ngIf="tokenPage">
      <p>
        Preencha o campo abaixo com o token recebido em seu e-mail. Favor verificar também sua caixa de SPAM.
      </p>
      <mat-form-field appearance="outline">
        <mat-label>Token</mat-label>
        <input type="text" [formControl]="tokenFC" [(ngModel)]="changePasswordData.token" matInput
          placeholder="Informe o token recebido em seu email">
      </mat-form-field>
      <mat-error *ngIf="tokenFC.touched && tokenFC.hasError('required')">
        Preenchimento de token <strong>obrigatório</strong>
      </mat-error>
      <mat-error *ngIf="errorToken">
        O token preenchido não pode ser validado. Tente novamente.
      </mat-error>
      <section class="c-recoverpassword__btn">
        <button [disabled]="tokenFC.invalid" (click)="tokenForPassword()" mat-raised-button
          color="primary">Validar</button>
      </section>
    </div>

    <div *ngIf="newPassPage">
      <form [formGroup]="changePasswordFB" (ngSubmit)="recoveryPassword()">
        <p>
          Preencha os campos abaixo para cadastrar sua nova senha.
        </p>
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Nova senha</mat-label>
            <input type="password" formControlName="newPassword" [errorStateMatcher]="matcher" required matInput
              [(ngModel)]="changePasswordData.newPassword" placeholder="Digite a nova senha">
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Confirme a nova senha</mat-label>
            <input type="password" formControlName="confirmPassword" [errorStateMatcher]="matcher" required matInput
              [(ngModel)]="changePasswordData.confirmPassword" placeholder="Confirme a nova senha">
          </mat-form-field>
        </p>

        <mat-error *ngIf="validEqualPasswordConfirm && changePasswordData.newPassword  !== changePasswordData.confirmPassword">
          Ops! As senhas não são iguais.
        </mat-error>
        <mat-error *ngIf="errorRequest">
          A operação não foi bem sucedida
        </mat-error>
        <section class="c-recoverpassword__btn">
          <button [disabled]="changePasswordFB.invalid" mat-raised-button color="primary">Confirmar alteração de
            senha</button>
        </section>
      </form>
    </div>

  </section>
</section>