import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { debounceTime, map, tap, retryWhen, take, delay } from 'rxjs/operators';
import * as _topojson from "topojson";
import { Alert } from './entities/alert';
import { BASE_URL, GEOSERVER_URL, URL_API } from 'src/environments/environment';
import { TI } from './entities/ti';
import { VectorLayer } from './entities/vector-layer';
import { User } from './entities/user.entity';
import { AuthService } from './auth.service';
import * as _ from 'underscore';
import * as turf from '@turf/turf';
//import { AlertsFilter } from '../shared/interfaces/filter.interface';
import { LatLng } from 'leaflet';
import { Feature, FeatureCollection, Geometry, Polygon } from 'geojson';
import { AlertValidationGeeImage } from '../shared/types';
import { layer } from '@fortawesome/fontawesome-svg-core';

const topojson = _topojson as any;

@Injectable()
export class ApiService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  /**
   * creates an alert
   */
  createAlert(params: {}): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/new`, params).pipe(
      map(response => {
        return response
      }),
    )
  }

  /**
   * deletes alerts
   */
  deleteAlerts(alertIds: number[], token): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/groupRemove`, {'ids': alertIds, 'token': token}).pipe(
      map(response => {
        return response
      })
    )
  }

  /**
   * Updates alerts
   */
  async updateAlert(params: {}) {
    return this.http.post<any[]>(`${URL_API}/alertas/validation`, params).pipe(
      map(response => response[0]),
    ).toPromise();
  }

  /**
   * Gets alerts
   */
  getAlerts(): Observable<Alert[]> {
    return this.http.get<Alert[]>(`${URL_API}/alertasro`);
  }

  /**
   * Gets alert by id
   */
  getAlertById(id: number): Observable<Alert> {
    return this.http.get<Alert>(`${URL_API}/alertasro/${id}`).pipe(
      map(response => Object.assign(new Alert(), response)),
    );
  }

  getNextAlert(id: number): Observable<Alert> {
    return this.http.get<Alert>(`${URL_API}/alertas/consulta/next/${id}`).pipe(
      map(response => Object.values(response)[0]),
      map(alert => Object.assign(new Alert(), alert))
    );
  }


  getPreviousAlert(id: number): Observable<Alert> {
    return this.http.get<Alert>(`${URL_API}/alertas/consulta/previous/${id}`).pipe(
      map(response => Object.values(response)[0]),
      map(alert => Object.assign(new Alert(), alert))
    );
  }

  /**
   * Gets alert sources
   */
  getAlertSources(query: { territorial_category?: any, class_list?: string[], ti_list?: number[] } = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/list/source`, query).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Gets alert satellite type
   */
  getAlertSatellite(): Observable<any[]> {
    return this.http.get<any[]>(`${URL_API}/alertas/list/satellite`).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Gets alert sizes
   */
  getAlertSizes(): Observable<any[]> {
    return this.http.get<any[]>(`${URL_API}/alertas/list/sizerange`).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Gets alert types class
   */
  getAlertClasses(query: { territorial_category?: any, ti_list?: number[], source_list?: string[] } = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/list/classes`, query).pipe(
      map(response => {
        return response.map(item => {
          item.source = item.source.replace('{', '').replace('}', '');
          item.source = (item.source as string).split(',').map(element => {
            if (element == 'ALERT') {
              element = 'SMDK';
            }
            return element;
          }).join(', ');
          return item;
        });
      })
    );
  }

  /**
   * Gets alert types class
   */
  getVectorLayers(query: { ti_list?: number[], class_list?: string[], source_list?: string[], buffer?: number } = null): Observable<VectorLayer[]> {
    return this.http.post<VectorLayer[]>(`${URL_API}/alertas/list/territorialcategory`, query).pipe(
      map(result => {

        const newResult = [];

        const resultByCategoria = _.groupBy(result, item => item.categoria);

        Object.keys(resultByCategoria).forEach(categoria => {

          const dataCategoria = resultByCategoria[categoria];

          if (dataCategoria[0].categoria == 'Unidades de Conservação - Estadual') {
            let elementCategoria = {
              id: 'uc:estadual',
              categoria: dataCategoria[0].categoria,
              name: 'Todas as UC Estaduais'
            };
            newResult.push(elementCategoria);
          }

          if (dataCategoria[0].categoria == 'Unidades de Conservação - Federal') {
            let elementCategoria = {
              id: 'uc:federal',
              categoria: dataCategoria[0].categoria,
              name: 'Todas as UC Federais'
            };
            newResult.push(elementCategoria);
          }

          dataCategoria.forEach(element => {
            newResult.push(element)
          });

        });

        return newResult;
      })
    );
  }

  /**
   * Gets alert layer
   */
  getVectorLayerById(id: any, categoria: string): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/geometry/request`, {
      idCategoria: id,
      categoria: categoria
    }).pipe(
      map(result => {
        let geom = result.reduce((accum, item) => {
          return turf.union(accum, turf.feature(JSON.parse(item.geom)));
        }, turf.feature(JSON.parse(result[0].geom)));
        return geom;
      }),
    )
  }

  /**
   * Gets ti geometry features
   *
   * @returns
   * @memberof ApiService
   */
  getGeomFeaturesTi(query: { territorial_category?: any, class_list?: string[], source_list?: string[] } = null): Observable<TI[]> {
    // const cache = localStorage.getItem('ti');
    // if (cache) {
    //   return of(JSON.parse(cache));
    // }
    return this.http.post<TI[]>(`${URL_API}/alertas/list/ti`, query, { responseType: 'json' }).pipe(
      // tap((response) => {
      //   try {
      //     localStorage.setItem('ti', JSON.stringify(response));
      //   } catch (e) {
      //     console.log(e);
      //   }
      // }),
      map(response => {
        return response;
      })
    );
  }

  /**
  * Gets alerts graph
  */
  // getAlertsGraphs(query: { idTI?: number, classe?: string, categoria?: string, idCategoria?: number } = null): Observable<any[]> {
  //   const httpParams = new HttpParams({
  //     fromObject: query as any,
  //   });
  //   return this.http.get<any[]>(`${URL_API}/alertas/graph/abs`, {
  //     params: httpParams
  //   }).pipe(
  //     debounceTime(400),
  //   );
  // }

  getChartDataClasses(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph/abs`, query).pipe(
      debounceTime(400)
    );
  }

  getChartDataBySizes(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph/range`, query).pipe(
      debounceTime(400)
    );
  }

  getChartDataByTerritory(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph/territory`, query).pipe(
      debounceTime(400)
    );
  }

  getChartDataByTiBuffer(query: {} = null): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/graph/tibuffer `, query).pipe(
      debounceTime(400),
    );
  }

  updateUser(user: Partial<User>): Observable<any> {
    const { token = "" } = this.authService.getAuthorization();
    return this.http.post(`${URL_API}/user/update`, user, { responseType: 'text' as 'json' });
  }

  selectByRegion(geom): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/alertas/selectbyRegion`, geom).pipe(
      map(response => {
        return response
      })
    );
  }

  // async getPointInfo(point): Promise<any> {
  //   return this.http.post<any>(`${URL_API}/inspector`, {
  //     lat: point[1],
  //     lng: point[0]
  //   }).toPromise();

  async getPointInfo(latLng: L.LatLng): Promise<any> {
    return this.http.post<any>(`${URL_API}/inspector`, latLng).toPromise();
  }

  async getPointHistory(latLng: L.LatLng): Promise<any> {
    return this.http.get<any>(`https://plataforma.brasil.mapbiomas.org/downloads/history_point?lat=${latLng?.lat}&lng=${latLng?.lng}`).toPromise();
  }

  async getPointFireHistory(latLng: L.LatLng): Promise<any> {
    return this.http.get<any>(`https://plataforma.brasil.mapbiomas.org/downloads/history_point_fire?lat=${latLng?.lat}&lng=${latLng?.lng}`).toPromise();
  }

  getWfsFeatureByLatLng(latLng: L.LatLng, buffer: number = 0):Promise<FeatureCollection> {

    const pointBuffer = turf.buffer(turf.point([latLng.lng, latLng.lat]), 0.0001, { units: 'kilometers' });

    const payload = {
      service: 'WFS',
      request: 'GetFeature',
      version: '1.0.0',
      typeName: 'kanindeRO:alerts',
      maxFeatures: '1',
      outputFormat: 'application/json',
      CQL_FILTER: `INTERSECTS("geom",POLYGON((${pointBuffer.geometry.coordinates[0].map(c => c[0] + ' ' + c[1]).join(', ')})))`
      // CQL_FILTER: `${cqlFilter != '' ? ('('+cqlFilter+') AND '):''} INTERSECTS("GEOM",POLYGON((${pointBuffer.geometry.coordinates[0].map(c => c[0] + ' ' + c[1]).join(', ')}))) AND ALERT_DELETED_AT is null`
    };

    const httpParams = new HttpParams({
      fromObject: payload
    });

    return this.http.get<FeatureCollection>(`${GEOSERVER_URL}/wfs`, { params: httpParams }).pipe(
      map(response => {
        return response
      }),
    ).toPromise();
  }

  async getWfsFeatureById(id: number): Promise<FeatureCollection> {
    const payload = {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'kanindeRO:alerts',
      outputFormat: 'application/json',
      CQL_FILTER: `id = ${id}`,
    };
    const httpParams = new HttpParams({
      fromObject: payload
    });

    return this.http.get<FeatureCollection>(`${GEOSERVER_URL}/wfs`, { params: httpParams }).toPromise();
  }

  getWfsAlertByPolygon(polygon: Polygon):Promise<FeatureCollection> {
    const payload = {
      service: 'WFS',
      request: 'GetFeature',
      version: '1.0.0',
      typeName: 'kanindeRO:alerts',
      outputFormat: 'application/json',
      CQL_FILTER: `INTERSECTS("geom",POLYGON((${polygon.coordinates[0].map(c => c[0] + ' ' + c[1]).join(', ')})))`
    };
    const httpParams = new HttpParams({
      fromObject: payload
    });
    return this.http.get<FeatureCollection>(`${GEOSERVER_URL}/wfs`, { params: httpParams }).toPromise();
  }

  getWfsProdesByPolygon(polygon: Polygon):Promise<FeatureCollection> {
    const payload = {
      service: 'WFS',
      request: 'GetFeature',
      version: '1.0.0',
      typeName: 'kanindeRO:geo_prodes',
      outputFormat: 'application/json',
      CQL_FILTER: `INTERSECTS("geom",POLYGON((${polygon.coordinates[0].map(c => c[0] + ' ' + c[1]).join(', ')})))`
    };
    const httpParams = new HttpParams({
      fromObject: payload
    });
    return this.http.get<FeatureCollection>(`${GEOSERVER_URL}/wfs`, { params: httpParams }).toPromise();
  }

  // getStateGeom(): Promise<FeatureCollection> {
  //   return this.http.get<FeatureCollection>(`/assets/ro-geom.json`).toPromise();
  // }

  getAlertImages(params: {
    polygon: string,
    dateBefore: string,
    date: string,
    tempo: string,
    satellite: string,
    draw: boolean,
    bands: string,
    cloudCover: number
  }): Observable<AlertValidationGeeImage[]> {
    const alertValidationImages$ = new Observable<AlertValidationGeeImage[]>((subscriber) => {

      // Update when endpoint changes
      // ${URL_API}/getImageList
      ajax.post(`https://kaninde.solved.eco.br:8002/getImageList`, params)
        .then((result: any) => {
          const images: AlertValidationGeeImage[] = result.map((imgIds: any) => {
            return {
              imgID: imgIds,
              draw:false
            };
          });
          subscriber.next(images);
          return { result, images };
        })
        .then((response) => {
          return Promise.all(response.result.map((imgId: string, index: number) => {
            return new Observable<AlertValidationGeeImage>((subscriber2) => {
              // Also update this one
              ajax.post('https://kaninde.solved.eco.br:8002/getThumb', {
                polygon: params.polygon,
                draw: false,
                image: imgId,
                order: index,
                satellite: params.satellite,
                bands: params.bands,
                cloudCover: params.cloudCover
              }).then(image => {
                response.images[index] = image as AlertValidationGeeImage;
                subscriber.next(response.images);
                subscriber2.next(response.images[index]);
                //subscriber2.complete();
              }).catch(error => {
                subscriber2.error(error);
              })
            }).pipe(retryWhen(errors => errors.pipe(delay(2000), take(5)))).toPromise()
          }));
        })

    })
    return alertValidationImages$;
  }

  getImagesThumbsByGeom(geom: Geometry, imageNames:string[], _params: {
    satellite: string
    bands: string,
    cloudCover: number
  }): Promise<AlertValidationGeeImage[]> {

    const alertValidationImages$ = new Observable<AlertValidationGeeImage[]>((subscriber) => {

      const params = {
        polygon: JSON.stringify(geom),
        ..._params
      };

      new Promise(resolve => {
        resolve(imageNames)
      }).then((result: any) => {
        const images: AlertValidationGeeImage[] = result.map((imgIds: any) => {
          return {
            imgID: imgIds
          };
        });
        return { result, images };
      })
        .then((response) => {
          return Promise.all(response.result.map((imgId: string, index: number) => {
            return new Observable<AlertValidationGeeImage>((subscriber2) => {
              ajax.post('https://kaninde.solved.eco.br:8002/getThumb', {
                polygon: params.polygon,
                image: imgId,
                order: index,
                draw: false,
                satellite: params.satellite,
                bands: params.bands,
                cloudCover: params.cloudCover
              }).then(image => {
                response.images[index] = image as AlertValidationGeeImage;
                subscriber.next(response.images);
                subscriber2.next(response.images[index]);
                subscriber2.complete();
              }).catch(error => {
                subscriber2.error(error);
              })
            }).pipe(retryWhen(errors => errors.pipe(delay(2000), take(5)))).toPromise()
          }));
        })
        .then((response) => {
          // subscriber.next(response);
          subscriber.complete();
        }).catch(error => {
          subscriber.error(error);
        })
    }).pipe(
      retryWhen(errors => errors.pipe(delay(2000), take(10)))
    );
    return alertValidationImages$.toPromise().then(result => result ? result.filter(r => r != undefined) : []);
  }

  uploadShapefile (file: File, fonte: string, qtd_arq: number) {
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('fonte', fonte);
    formData.append('qtd_arq', qtd_arq.toString());

    return this.http.post<any>(`${URL_API}/upload/uploadshp`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(response => {
        return response;
      })
    )
  }

  getScheduleBySource(source: string): Observable<any> {
    return this.http.get<any>(`${URL_API}/agendamento/` + source).pipe(
      map(response => {
        return response;
      }),
  );
  }

  saveSchedule(schedulingInfo: {}, source: string): Observable<any> {
    return this.http.post<any>(`${URL_API}/agendamento`, {
      arr: schedulingInfo,
      source: source
    })
  }

  getFields(source: string): Observable<{geo_fields: any, shp_fields: any, relationship_fields: any}> {
    return this.http.get<{geo_fields: any, shp_fields: any, relationship_fields: any}>(`${URL_API}/relation/input/columns/` + source).pipe(
      map(response => {
        return response
      }),
      // tap(response => console.log('response:', response))
    );
  }

  saveRelationship(source, relationship): Observable<any[]> {
    return this.http.post<any[]>(`${URL_API}/relation/input/relationship`, {
      source: source,
      arr: relationship
    }).pipe(
      map(response => {
        return response;
      }),
    );
  }
}

export interface ServiceResponse<T> {
  data: T;
}

const ajax = {
  post: (url: string, params: any) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: "POST",
        url: url,
        contentType: 'application/x-www-form-urlencoded; charset=utf-8',
        timeout: 20000,
        error: () => reject,
        data: params,
        dataType: 'json',
      }).done((response: any) => resolve(response))
        .fail((jqXHR: any, textStatus: any, errorThrown: any) => {
          reject(textStatus);
        })
    });
  }
}
