import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from './api.service';
import { User } from './entities/user.entity';
import { URL_API } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',

})
export class UsersService {


  constructor(
    private http: HttpClient,
    private authService: AuthService,

  ) {

  }

  /**
   * User list
   * 
   * @param {any} [query=null] 
   * @returns 
   * @memberof UsersService
   */
  list(query: any = null): Observable<User[]> {

    const httpParams = new HttpParams({
      fromObject: query
    });

    return this.http.get<User[]>(`${URL_API}/users`, {
      params: httpParams
    }).pipe(
      map(response => {
        return response.map(user => Object.assign(new User(), user))
      })
    );
  }

  /**
   * Save user data
   * 
   * @param {*} user 
   * @returns {Observable <any>}
   * @memberof UsersService
   */
  save(user: any): Observable<any> {
    return this.http.post<any>(`${URL_API}/user/new`, user, {
      responseType: 'text' as 'json',
    });
  }

  /**
   * Update user data
   * 
   * @param {*} user 
   * @returns {Observable <any>}
   * @memberof UsersService
   */
  update(user: any): Observable<any> {
    return this.http.post<any>(`${URL_API}/user/update`, user, {
      responseType: 'text' as 'json'
    });
  }

  /**
   * Remove user 
   * 
   * @param {*} user 
   * @returns {Observable <any>}
   * @memberof UsersService
   */
  delete(user: User): Observable<any> {
    return this.http.post<any>(`${URL_API}/user/delete`, user, {
      responseType: 'text' as 'json'
    });
  }

  /**
 * Change password
 * @param {*} user 
 * @returns {Observable <any>}
 */
  changePassword(data: any): Observable<any> {
    const body = {
      password: `${data.old_password}`,
      newpassword: `${data.password}`
    }
    return this.http.post<any>(`${URL_API}/passwordchange`, body);
  }

/**
 * Recovery password step 3
 * @param {*} user 
 * @returns {Observable <any>}
 */
    recoveryPassword(data: any): Observable<any> {
    const body = {
      email: `${data.email}`,
      token: `${data.token}`,
      password: `${data.newPassword}`
    }
    return this.http.post<any>(`${URL_API}/passwordtokenchange`, body);
  }

  /**
 * Recovery password step 2
 * @param {*} user 
 * @returns {Observable <any>}
 */
   tokenForPassword(data: any): Observable<any> {
    const body = {
      email: `${data.email}`,
      token: `${data.token}`,
    }
    return this.http.post<any>(`${URL_API}/passwordtoken`, body);
  }

    /**
 * Recovery password step 1
 * @param {*} user 
 * @returns {Observable <any>}
 */
     emailForPassword(data: any): Observable<any> {
      const body = {
        email: `${data.email}`,
      }
      
      return this.http.post<any>(`${URL_API}/passwordreminder`, body,
      );
    }
}