import { Component, OnInit, Renderer2, ViewChild, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { onMapReferenceLayerChange } from '../map.events';
import { OVERLAYERS } from './overlayers';
@Component({
  selector: 'app-overlayer',
  templateUrl: './overlayer.component.html',
  styleUrls: ['./overlayer.component.scss']
})
export class OverLayerComponent implements OnInit {
  @ViewChild('overLayerPanel') overLayerPanel: ElementRef;
  @Input() statusPanel : boolean;
  @Output() openLayerEvent = new EventEmitter<string>();

  el: ElementRef;
  referenceLayers = {};
  color = '#008837';
  checked = false;
  disabled = false;
  show = [];
  scrHeight:any;
  scrWidth:any;

  overlayers = OVERLAYERS;
  columns = [
    this.overlayers.slice(0, Math.ceil(this.overlayers.length / 2)),
    this.overlayers.slice(Math.ceil(this.overlayers.length / 2))
  ];

  constructor(private renderer: Renderer2,el: ElementRef) {this.el = el; this.getScreenSize()}

  @HostListener('window:resize', ['$event'])

  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth  = window.innerWidth;
  }

  ngOnInit(): void {
    let localStorageLAyer = localStorage.getItem('referenceLayers');

    if (localStorageLAyer) {
      this.referenceLayers = JSON.parse(localStorageLAyer);
    } else {
    }
  }

  layerSelected(event) {
    onMapReferenceLayerChange.emit(this.referenceLayers);
    localStorage.setItem('referenceLayers', JSON.stringify(this.referenceLayers));
  }

  openAccordion(value: number) {
    this.show[value] = !this.show[value];
    if (this.show.length) {
      for (let item of this.show.keys()) {
        if (item !== value) {
          this.show[item] = false;
        }
      }
    }
  }

  close() {
    this.openLayerEvent.emit('over');
  }

  ngAfterViewInit() {
    const hostElem = this.el.nativeElement;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.statusPanel == true && !hostElem.parentNode.contains(e.target) && !this.overLayerPanel.nativeElement.contains(e.target)) {
        this.close();
      }
    });
  }
}
