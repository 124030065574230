<section class="c-alert-details"
  [ngClass]="statusHeader === 'CLOSE' ? 'c-map-statistic-animate-top custom-dynamic-class-expansion-panel-max' : 'c-map-statistic-animate-bottom custom-dynamic-class-expansion-panel-min'">
  <div class="c-alert-details__header">
    <span class="c-alert-details__title">Metadados</span>
    <span (click)="handleGoBackHome()">X</span>
  </div>
  <hr />
  <section class="c-alert-details-wrapper">
    <div class="c-alert-details__contant">
      <div class="contant__date" *ngIf="alert.id !== undefined ">
        <div class="contant__date--collumn">
          <span>Antes</span>
          <span>{{alert?.getDateBefore() | date:'dd/MM/yyyy'}}</span>
        </div>
        <div class="contant__date--collumn">
          <span>Depois</span>
          <span>{{alert?.getDateAfter() | date:'dd/MM/yyyy'}}</span>
        </div>
        <div *ngIf="alert.id" class="contant__date--collumn">
          <span>Área</span>
          <span>{{alert?.getGeometryArea()}} ha</span>
        </div>
      </div>
      <div class="contant__date" *ngIf="alert.id === undefined">
        <div class="contant__date--collumn"><span style="font-size: 30px; color: #D60604;">Alerta Inexistênte</span>
        </div>
      </div>
      <ng-container *ngIf="alert?.getSourceLabel() != null && !imgUndefined">
        <app-images-swap-slider [alert]="alert"></app-images-swap-slider>
        <div class="contant__footer-detail">
          <div class="contant__footer-detail--collumn">
            <span>
              <a href="{{URL_img_before}}" target="_blank">Antes</a>

            </span>
            <span>
              <a href="{{URL_img_after}}" target="_blank">Depois</a>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="alert.id && imgUndefined">
        <div style="width: 347px;
        height: 340px;
        left: 14.12px;
        top: 128.4px;
        display: flex;
        align-items: center;
        justify-content:center;
        background: #C4C4C4;
        border-radius: 8px;" class="contant__footer-detail--collumn">
          <div style="width: 190px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 66px;
          left: 93.12px;
          top: 255.4px;
          background: #FFFFFF;
          box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4);
          border-radius: 4px;">
            <img style="width: 33px; height: 33px" src="../../../../../assets/img/disclaimer.svg" alt="disclaimer" />
            <span style="font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 140%;

            /* or 25px */
            text-align: center;

            /* Grayscale/400 */
            color: #868E96;

            ">Antes e Depois Não Disponível</span>
          </div>
        </div>

      </ng-container>


      <ng-container *ngIf=" alert.id == undefined">
        <div style="
        /* width: 347px; */
        height: 340px;
        left: 14.12px;
        top: 128.4px;
        display: flex;
        align-items: center;
        justify-content:center;
        background: #C4C4C4;
        border-radius: 8px;" class="contant__footer-detail--collumn">
          <div style="width: 190px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 66px;
          left: 93.12px;
          top: 255.4px;
          background: #FFFFFF;
          box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4);
          border-radius: 4px;">
            <img style="width: 33px; height: 33px" src="../../../../../assets/img/disclaimer.svg" alt="disclaimer" />
            <span style="font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 140%;

            /* or 25px */
            text-align: center;

            /* Grayscale/400 */
            color: #868E96;

            ">Antes e Depois Não Disponível</span>
          </div>
        </div>

      </ng-container>
      <hr />

      <div class="contant__date" *ngIf="alert.id === undefined">
        <div style="text-align: justify; text-justify: inter-word;" class="contant__date--collumn">
          <span>Certifique-se de que o ID do alerta digitado na URL está correto e tente novamente.</span>
        </div>
      </div>

      <div class="contant__date" *ngIf="alert.id !== undefined ">
        <div class="contant__date--collumn">
          <span>ID</span>
          <span>{{alert.id}}</span>
        </div>
        <div class="contant__date--collumn">
          <span>Sistema</span>
          <span *ngIf="alert">{{alert?.getSourceLabel()}}</span>
        </div>
        <div class="contant__date--collumn">
          <span>Classe</span>
          <span>{{alert.type}}</span>
        </div>
        <div class="contant__date--collumn">
          <span>Validação</span>
          <div *ngIf="!alert.validation && alert.source != 'Mapbiomas' && alert.source != 'SAD';  else elseBlock"
            style="display: flex; justify-content: center; align-items: center;">
            <span style="margin-right: 16px;">Não</span>
            <a href="javascript:">
              <img class="icon-default" [style.opacity]="0.3" src="../../../../assets/img/download.svg" alt="">
            </a>
          </div>
          <ng-template #elseBlock>
            <div style="display: flex; justify-content: center; align-items: center;">
              <span style="margin-right: 16px;">Sim</span>
              <a *ngIf="(alert.type_validated != 'Falso Positivo' && alert.type_validated != 'Deslocamento / Falha PRODES' && alert.type_validated != 'Inconclusivo') || (alert.source == 'Mapbiomas')|| (alert.source == 'SAD');  else elseBlockValidated"
                target="_blank" href="{{ENDPOINT_API}}/alerts/report/{{alert_id}}">
                <img class="icon-default" src="../../../../assets/img/download.svg" alt="">
              </a>
              <ng-template #elseBlockValidated>
                <a href="javascript:">
                  <img class="icon-default" [style.opacity]="0.3" src="../../../../assets/img/download.svg" alt="">
                </a>
              </ng-template>
            </div>
          </ng-template>
        </div>

        <div *ngIf="alert.validation === true" class="contant__date--collumn">
          <span>Classe Validada</span>
          <span>{{alert.type_validated}}</span>
        </div>

        <div *ngIf="alert.validation === true" class="contant__date--collumn">
          <span>Data da Validação</span>
          <span>{{alert?.getDateValidation() | date:'dd/MM/yyyy'}}</span>
        </div>
        <div class="contant__date--collumn">
          <span>Dentro de TIs</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0]?.ti ? alert.materialized[0]?.ti :'Não' ) == 'Sim'}">
            {{alert.materialized && alert.materialized[0]?.ti ? alert.materialized[0]?.ti :'Não' }}
          </span>
        </div>

        <div class="contant__date--collumn">
          <span>Dentro UCs</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0]?.uc_name != '{NULL}' ? 'Sim' :'Não' ) == 'Sim'}">
            {{alert.materialized && alert.materialized[0]?.uc_name != '{NULL}' ? 'Sim' :'Não' }}
          </span>
        </div>

        <div class="contant__date--collumn">
          <span>Dentro de Área Embargada</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0] ? alert.materialized[0]?.embargoed :'Não' ) == 'Sim'}">
            {{alert.materialized && alert.materialized[0] ? alert.materialized[0]?.embargoed :'Não' }}
          </span>
        </div>

        <div class="contant__date--collumn">
          <span>Dentro de CARs</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0] ? alert.materialized[0]?.car_name :'Não' ) == 'Sim'}">
            {{alert.materialized && alert.materialized[0] ? alert.materialized[0]?.car_name :'Não' }}
          </span>
        </div>

        <div class="contant__date--collumn">
          <span>Dentro de APP</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0] ? alert.materialized[0]?.app :'Não' ) == 'Sim'}">
            {{alert.materialized && alert.materialized[0] ? alert.materialized[0]?.app :'Não' }}
          </span>
        </div>

        <div class="contant__date--collumn">
          <span>Dentro de Reserva Legal</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0] ? alert.materialized[0]?.reserva_legal_name :'Não') == 'Sim'}">
            {{alert.materialized && alert.materialized[0] ? alert.materialized[0]?.reserva_legal_name :'Não'}}
          </span>
        </div>
        <div class="contant__date--collumn">
          <span>Dentro de Assentamento</span>
          <span
            [ngClass]="{'text-red': (alert.materialized && alert.materialized[0] ? alert.materialized[0]?.assentamento_name :'Não') == 'Sim'}">
            {{alert.materialized && alert.materialized[0] ? alert.materialized[0]?.assentamento_name :'Não'}}
          </span>
        </div>

      </div>
    </div>
  </section>
</section>
