import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from 'src/app/services/auth.service';
import { Authorization } from 'src/app/services/authorization';
import { AboutComponent } from 'src/app/shared/components/about/about.component';
import { URL_DASHBOARD, URL_VALIDATION_MODULE, URL_UPLOAD } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @ViewChild('sidenav', { static: true })
  sidenav: MatSidenav;

  URL_VALIDATION_MODULE = URL_VALIDATION_MODULE;
  URL_UPLOAD = URL_UPLOAD;
  URL_DASHBOARD = URL_DASHBOARD;

  authorization: Authorization;
  modalComponents: {};


  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
  ) {
    this.authorization = this.authService.getAuthorization();
    this.modalComponents = {
      'AboutComponent': AboutComponent,
    }
  }

  ngOnInit(): void {
    OnSideNavToggle.subscribe(() => {
      this.sidenav.toggle();
    });
  }

  openDialog(component, width): void {
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`
    });
  }

}

export const OnSideNavToggle = new EventEmitter();
