<!-- <main class="d-flex w-100">
    <div class="container d-flex flex-column">
        <div class="row vh-100">
            <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            </div>
        </div>
    </div>
</main>
<router-outlet></router-outlet> -->

<main class="l-main">
  <app-header-login></app-header-login>
  <section class="l-content-login">
    <router-outlet></router-outlet>
  </section>
</main>
