import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet.vectorgrid';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure';
import * as turf from '@turf/turf';

import { Component, OnInit, ComponentFactoryResolver, Injector, ApplicationRef, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { URL_GEO_API } from "src/environments/environment";

import { BaseLayers } from './map-layers';
import { ApiService } from "src/app/services/api.service";
import { onClickMapZoom, onClickMarginTop } from "src/app/pages/page-events";
import { onHeaderFilterChange } from "src/app/shared/components/header/header.events";
import { onMapBaseLayerChange, onMapFilterDateChange, onMapInspectorClick, onMapInspectorClosed, onMapLoadData, onMapReferenceLayerChange, onUserCoordinatesChange } from "./map.events";
import { onAlertDetailClosed, onAlertDetailLoad } from "./alerts/alert-detail/alert-detail.events";
import { AlertClasseColor, AlertClasses } from 'src/app/constants/alert-classes';
import { FeatureCollection, Polygon } from 'geojson';

// delete L.Icon.Default.prototype['_getIconUrl'];
// const iconDefault = L.icon({
//   iconRetinaUrl: './assets/marker-icon-2x.png',
//   iconUrl: './assets/marker-icon.png',
//   shadowUrl: './assets/marker-shadow.png',
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
//   popupAnchor: [1, -34],
//   tooltipAnchor: [16, -28],
//   shadowSize: [41, 41]
// });

// L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @ViewChild('mapComp', { static: true })
  mapElement: ElementRef;

  map: L.Map;
  marker = null;

  control: L.Control;

  layers: {
    base?: L.LayerGroup,
    reference?: L.LayerGroup,
    alerts?: L.TileLayer,
    alertSelected?: L.LayerGroup,
    ti?: L.LayerGroup,
    vector?: L.LayerGroup
  } = {};

  baseLayers = {
    Maps: BaseLayers.GOOGLE.Maps.factoryLeafletTileLayer(),
    Satellite: BaseLayers.GOOGLE.Satellite.factoryLeafletTileLayer(),
    SatelliteHybrid: BaseLayers.GOOGLE.SatelliteHybrid.factoryLeafletTileLayer(),
    Dark: BaseLayers.CARTO.Dark.factoryLeafletTileLayer(),
    Roads: BaseLayers.GOOGLE.Roads.factoryLeafletTileLayer(),
    OSMStandard: BaseLayers.OSM.Standard.factoryLeafletTileLayer(),
    Planet: BaseLayers.Planet.Mosaic.factoryLeafletTileLayer(),
    MapBiomasClassification: BaseLayers.MapBiomas.Classification.factoryLeafletTileLayer(),
  };

  referenceLayers = {
    Municipios: BaseLayers.Reference.Municipios.factoryLeafletTileLayer(),
    Quilombos: BaseLayers.Reference.Quilombos.factoryLeafletTileLayer(),
    CAR: BaseLayers.Reference.CAR.factoryLeafletTileLayer(),
    PRODES: BaseLayers.Reference.PRODES.factoryLeafletTileLayer(),
    UC: BaseLayers.Reference.UC.factoryLeafletTileLayer(),
    TI: BaseLayers.Reference.TI.factoryLeafletTileLayer(),
    TIb3: BaseLayers.Reference.TIb3.factoryLeafletTileLayer(),
    TIb5: BaseLayers.Reference.TIb5.factoryLeafletTileLayer(),
    TIb10: BaseLayers.Reference.TIb10.factoryLeafletTileLayer(),
    RODOVIAS: BaseLayers.Reference.RODOVIAS.factoryLeafletTileLayer(),
    MalhaFundiaria: BaseLayers.Reference.MalhaFundiaria.factoryLeafletTileLayer(),
    ASSENTAMENTOS: BaseLayers.Reference.ASSENTAMENTOS.factoryLeafletTileLayer(),
    EMBARGOS: BaseLayers.Reference.EMBARGOS.factoryLeafletTileLayer(),
    AUTORIZACOES: BaseLayers.Reference.AUTORIZACOES.factoryLeafletTileLayer(),
    ETNOZONEAMENTO: BaseLayers.Reference.ETNOZONEAMENTO.factoryLeafletTileLayer(),
    ALDEIAS: BaseLayers.Reference.ALDEIAS.factoryLeafletTileLayer(),
    SRTM: BaseLayers.Reference.SRTM.factoryLeafletTileLayer(),
    ANA: BaseLayers.Reference.ANA.factoryLeafletTileLayer(),
    FIRMS: BaseLayers.Reference.FIRMS.factoryLeafletTileLayer(),
    PISTAS: BaseLayers.Reference.PISTAS.factoryLeafletTileLayer(),
  };

  filter: any = {};


  static territoryCategory: string;

  mapInspectorLayer = L.featureGroup();

  inspectorControl;

  constructor(
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private apiService: ApiService,
    private router: Router,

  ) {

  }

  async ngOnInit() {

    this.map = new L.Map(
      this.mapElement.nativeElement,
      {
        center: [
          -10.362318788026419,
          -63.5180291571725
        ],
        zoom: 8,
        minZoom: 5,
        maxZoom: 18,
        zoomControl: false
      }
    );

    L.control.scale({ position: 'bottomright' }).addTo(this.map);

    let polylineMeasure = (L.control as any).polylineMeasure({
      position: 'bottomleft',
      unit: 'metres',
      showBearings: false,
      clearMeasurementsOnStop: true,
      showClearControl: false,
      showUnitControl: false,
      bearingTextIn: 'In',             // language dependend label for inbound bearings
      bearingTextOut: 'Out',          // language dependend label for outbound bearings
      tooltipTextFinish: 'Clique para <b>finalizar a linha</b><br>',
      tooltipTextDelete: 'Pressione SHIFT e clique para <b>apagar o ponto</b>',
      tooltipTextMove: 'Clique e arraste para <b>mover o ponto</b><br>',
      tooltipTextResume: '<br>Pressione CTRL e clique para <b>finalizar a linha</b>',
      tooltipTextAdd: 'Pressione CTRL e clique para <b>adicionar  um ponto</b>', // language dependend labels for point's tooltips
      measureControlTitleOn: 'Clique para medir distância',   // Title for the control going to be switched on
      measureControlTitleOff: 'Clique desativar a medição', // Title for the control going to be switched off
      measureControlLabel: '&#8614;', // Label of the Measure control (maybe a unicode symbol)
      measureControlClasses: [],      // Classes to apply to the Measure control
      clearControlTitle: 'Clear Measurements', // Title text to show on the clear measurements control button
      clearControlLabel: '&times',    // Label of the Clear control (maybe a unicode symbol)
      clearControlClasses: [],        // Classes to apply to clear control button
      distanceShowSameUnit: false,    // Keep same unit in tooltips in case of distance less then 1 km/mi/nm
      unitControlTitle: {             // Title texts to show on the Unit Control button
        text: 'Change Units',
        metres: 'metres',
        landmiles: 'land miles',
        nauticalmiles: 'nautical miles'
      },
    });

    polylineMeasure.addTo(this.map);

    // L.drawLocal.draw.toolbar.buttons.marker = 'Clique para extrair infomações do mapa.';
    // L.drawLocal.draw.handlers.marker.tooltip.start = 'Clique para extrair infomações do mapa.';

    // this.inspectorControl = new L.Control.Draw({
    //   position: 'bottomleft',
    //   draw: {
    //     polygon: false,
    //     rectangle: false,
    //     polyline: false,
    //     circle: false,
    //     circlemarker: false,
    //     marker: {
    //       icon: iconDefault,
    //     }
    //   }
    // });

    // this.map.addControl(this.inspectorControl);
    // this.map.addLayer(this.mapInspectorLayer);

    // this.map.on(L.Draw.Event.CREATED, async (e: any) => {
    //   const layer = e.layer;
    //   const geojson = e.layer.toGeoJSON();

    //   this.mapInspectorLayer.clearLayers();
    //   this.mapInspectorLayer.addLayer(layer);

    //   const pointInfo = await this.apiService.getPointInfo(geojson.geometry.coordinates as number[]);
    //   const pointHistory = await this.apiService.getPointHistory(e.layer._latlng);
    //   const pointFireHistory = await this.apiService.getPointFireHistory(e.layer._latlng);

    //   onMapInspectorClick.emit({
    //     lat: geojson.geometry.coordinates[0],
    //     lng: geojson.geometry.coordinates[1],
    //     // lat: latLng?.lat,
    //     // lng: latLng?.lng,
    //     info: pointInfo,
    //     history: pointHistory,
    //     fireHistory: pointFireHistory
    //   })
    // });

    // onMapInspectorClosed.subscribe(() => {
    //   this.mapInspectorLayer.clearLayers();
    // });


    onClickMapZoom.subscribe((zoom) => {
      console.log(zoom, this.map.getZoom() - 1)
      const controllZoom = {
        'zoomin': () => {
          // this.map.setZoom(this.map.getZoom() + 1)
        },
        'zoomout': () => {
          // this.map.setZoom(this.map.getZoom() - 1)
        }
      }
      controllZoom[zoom]();
    });

    this.initLayers();

    this.initEvents();

    this.map.invalidateSize();
  }

  ngOnDestroy() {
    this.map.clearAllEventListeners;
    this.map.remove();
  };

  private compilePopup(component, onAttach): any {
    const compFactory: any = this.resolver.resolveComponentFactory(component);
    let compRef: any = compFactory.create(this.injector);

    if (onAttach)
      onAttach(compRef);

    this.appRef.attachView(compRef.hostView);
    compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

    let div = document.createElement('div');
    div.appendChild(compRef.location.nativeElement);

    return div;
  }

  initLayers() {

    // Alerts
    let vectorStyle = (properties, zoom) => {
      return {
        color: AlertClasseColor[properties.type],
        opacity: 1,
        fillOpacity: 0.1,
        fill: true,
        fillColor: 'white',
      }
    };
    var vectorTileStyling1 = {
      mat_view_alerts: vectorStyle,
      mat_view_alerts_buf3: vectorStyle,
      mat_view_alerts_buf5: vectorStyle,
      mat_view_alerts_buf10: vectorStyle
    };

    this.layers.alerts = (L as any).vectorGrid
      .protobuf(`${URL_GEO_API}/v1/mvt/mat_view_alerts/{z}/{x}/{y}?geom_column=geom&columns=id,type,source`, {
        rendererFactory: (L as any).svg.tile,
        vectorTileLayerStyles: vectorTileStyling1,
        interactive: true,
        getFeatureId: function (f) {
          return f.properties.osm_id;
        }
      });

    this.map.addLayer(this.layers.alerts);
    this.layers.alerts.setZIndex(2000);

    this.layers.alertSelected = L.layerGroup();
    this.map.addLayer(this.layers.alertSelected);
    this.layers.alertSelected.setZIndex(2001);

    // others

    this.layers.ti = L.layerGroup();
    this.map.addLayer(this.layers.ti);
    this.layers.ti.setZIndex(2001);

    this.layers.vector = L.layerGroup();
    this.map.addLayer(this.layers.vector);
    this.layers.vector.setZIndex(2002);

    this.layers.base = L.layerGroup();
    this.map.addLayer(this.layers.base);
    let baseLayer = localStorage.getItem('baseLayer');
    if (baseLayer) {
      this.layers.base.addLayer(this.baseLayers[baseLayer]);
    } else {
      setTimeout(() => {
        this.layers.base.addLayer(this.baseLayers["Satellite"]);
        localStorage.setItem('baseLayer', 'Satellite');
      }, 200);
    }

    this.layers.reference = L.layerGroup();
    this.map.addLayer(this.layers.reference);
    let referenceLayers = JSON.parse(localStorage.getItem('referenceLayers'));
    if (referenceLayers) {
      Object.keys(referenceLayers).forEach((layerKey, index) => {
        if (referenceLayers[layerKey] === true && !this.map.hasLayer(this.referenceLayers[layerKey])) {
          this.layers.reference.addLayer(this.referenceLayers[layerKey]);
        }
        if (this.referenceLayers[layerKey].options.layers == 'kanindeRO:RO_srtm_v1') {
          // this.referenceLayers[layerKey].setZIndex(0);
        }else{
          this.referenceLayers[layerKey].setZIndex(index + 1000);
        }
      });
    }
  }

  initEvents() {

    this.map.on('zoomend', (event) => { });

    this.layers.alerts.on('click', (e: any) => {
      this.router.navigate(['alerts', e.layer.properties.id]);
      if (e.layer.properties.source == 'ALERT') { }
    });

    this.layers.alerts.on('loading', (e) => {
      //console.log('alerts layer loading');
    });

    this.layers.alerts.on('load', (e) => {
      //console.log('alerts layer load');
    });

    onMapBaseLayerChange
      .subscribe(layerKey => {
        this.layers.base.clearLayers();
        this.layers.base.addLayer(this.baseLayers[layerKey]);
      });

    onMapReferenceLayerChange
      .subscribe(referenceLayers => {
        Object.keys(referenceLayers).forEach((layerKey, index) => {
          if (referenceLayers[layerKey] === true && !this.map.hasLayer(this.referenceLayers[layerKey])) {
            this.layers.reference.addLayer(this.referenceLayers[layerKey]);
          } else if (!referenceLayers[layerKey] && this.map.hasLayer(this.referenceLayers[layerKey])) {
            this.layers.reference.removeLayer(this.referenceLayers[layerKey]);
          }
          if (this.referenceLayers[layerKey].options.layers == 'kanindeRO:RO_srtm_v1') {
            // this.referenceLayers[layerKey].setZIndex(-1);
          }else {
            this.referenceLayers[layerKey].setZIndex(index + 1000);
          }
        });
      });

    onHeaderFilterChange
      .subscribe(filter => {
        this.filter = Object.assign(this.filter, filter);
        this.updateTiLayer(this.filter.ti, this.filter.buffer);
        this.updateLayers();
        this.updateAlertsLayer();
      });

    // onUserCoordinatesChange
    //   .subscribe(coordinates => {
    //     if (coordinates.long != null && coordinates.lat != null) {
    //       if (this.marker == null) {
    //         this.marker = L.marker([parseFloat(coordinates.lat), parseFloat(coordinates.long)]).on('click', (e: any) => {
    //           this.map.removeLayer(this.marker);
    //         }).addTo(this.map);
    //       } else {

    //         this.map.removeLayer(this.marker);
    //         this.marker = L.marker([parseFloat(coordinates.lat), parseFloat(coordinates.long)]).on('click', (e: any) => {
    //           this.map.removeLayer(this.marker);
    //         }).addTo(this.map);
    //       }
    //       this.map.setView(new L.LatLng(parseFloat(coordinates.lat), parseFloat(coordinates.long)), 17)
    //     }
    //   });

    onClickMarginTop
      .subscribe(coordinates => {
        this.map.invalidateSize();
      });

    onAlertDetailLoad.subscribe(alert => {
      this.layers.alertSelected.clearLayers();
      if (alert) {
        let alertLayer = L.geoJSON(alert.geom, {
          style: {
            color: 'aliceblue',
            weight: 4,
            opacity: 1,
            fillOpacity: 0.1,
            fillColor: 'aliceblue',
          }
        });
        this.map.fitBounds(alertLayer.getBounds(), {
          maxZoom: 16
        });
        this.layers.alertSelected.addLayer(alertLayer);
      }
    });

    onAlertDetailClosed.subscribe(alert => {
      this.layers.alertSelected.clearLayers();
    });

    setTimeout(() => {
      this.map.invalidateSize()
    }, 200);


    const measureControl = document.getElementById('polyline-measure-control');

    $('.leaflet-draw-draw-marker, .c-controll-zoom, .c-controll-map-btn').click((() => {
      if ($('.polyline-measure-controlOnBgColor').length > 0) {
        measureControl.click();
      }
    }));
  }

  private async updateTiLayer(tis: any[], buffer = 0) {
    if (tis) {
      const tisLayer = tis.map(ti =>
        L.geoJSON(ti.geom, {
          style: {
            color: 'green',
            fill: false
          }
        })
      )

      // let tiLayer = L.geoJSON(ti.geom, {
      //   style: {
      //     color: 'green',
      //     fill: false
      //   }
      // });
      /*  let tiBufferLayer = L.geoJSON(turf.buffer(ti.geom, buffer), {
         style: {
           color: 'yellow',
           opacity: 0.5,
           weight: 1,
           fill: false
         }
       }); */
      this.layers.ti.clearLayers();
      //this.layers.ti.addLayer(tiBufferLayer);
      // this.layers.ti.addLayer(tiLayer);
      tisLayer.forEach(ti => {
        this.layers.ti.addLayer(ti);
      });
    }
  }

  async updateLayers() {
    if (!this.filter.vector || this.filter.vector.length === 0) {
      this.layers.vector.clearLayers();
    }

    let bounds = [];
    let tiGeom = null;
    let vectorGeom = null;

    if (this.filter.ti && this.filter.ti.length) {
      tiGeom = this.filter.ti.map((ti) => {
        return turf.feature(ti.geom)
      })
    }

    if (this.filter.vector && this.filter.vector.length > 0) {
      vectorGeom = await Promise.all(this.filter.vector.map(async v => {
        return (this.apiService.getVectorLayerById(v.id, v.categoria).toPromise());
      }));
    }

    if (vectorGeom) {
      let vectorLayer = vectorGeom.map(vg => {
        return L.geoJSON(vg as any, {
        style: {
          color: 'gainsboro',
          fill: false
          }
        })
      });
      this.layers.vector.clearLayers();
      vectorLayer.forEach(layer => {
        this.layers.vector.addLayer(layer);
        bounds.push(layer.getBounds());
      });
      this.map.fitBounds(bounds);
    }
    if (tiGeom && vectorGeom) {
      const featureCol: FeatureCollection<Polygon> = {
        type: "FeatureCollection",
        features: [...vectorGeom, ...tiGeom]
      };
      let geomLayer = L.geoJSON(turf.combine(featureCol))
      this.map.fitBounds(geomLayer.getBounds());
    }

    else if (tiGeom && !vectorGeom) {
      let geomLayer = L.geoJSON(tiGeom);
      this.map.fitBounds(geomLayer.getBounds());
    }

    // if (!tiGeom && !vectorGeom && !this.filter.alerts && !this.filter.alertSelected) {
    //   this.layers.vector.clearLayers();
    //   this.layers.ti.clearLayers();
    //   this.map.flyTo([
    //     -10.362318788026419,
    //     -63.5180291571725
    //   ], 8);
    // }

  }

  private async updateAlertsLayer() {

    const filter = this.filter;

    let urlParamsFilter = [];

    let table = 'mat_view_alerts';

    let tables = {
      0: 'mat_view_alerts',
      3: 'mat_view_alerts_buf3',
      5: 'mat_view_alerts_buf5',
      10: 'mat_view_alerts_buf10'
    };

    const urlParams = {
      geom_column: 'geom',
      columns: 'id,type',
      filter: ''
    };

    if (filter.class && filter.class.length) {
      const formatedArray = filter.class.map((value: any) => {
        return `"${value}"`;
      });
      const strFormatedArray = formatedArray.join(',');
      urlParamsFilter.push(`type = any('{${strFormatedArray}}')`);
    }

    if (filter.ti && filter.ti.length) {
      const tiParams = filter.ti.map((value: any) => `'${value.name}' = any(ti_name)`);
      urlParamsFilter.push(`(${tiParams.join(' OR ')})`);
    }

    if (filter.buffer) {
      table = tables[filter.buffer];
    }

    if (filter.dateStart && filter.dateEnd) {
      urlParamsFilter.push(`(date_insertion BETWEEN \'${new Date(filter.dateStart).toISOString().substr(0, 10)}\' and \'${new Date(filter.dateEnd).toISOString().substr(0, 10)}\')`);
    }

    if (filter.vector && filter.vector.length){
      let vectorParams = filter.vector.map(value => {
        if (value.categoria == 'Assentamento') {
          return (`${value.id} = any(assentamentos)`);
        }
        if (value.categoria == 'Municipio') {
          return(`\'${value.id}\' = any(municipio)`);
        }

        if (value.id == 'uc:federal') {
          return(`\'federal\' = any(uc_category)`);
        } else if (value.categoria == 'Unidades de Conservação - Federal') {
          return(`\'${value.name}\' = any(uc_name)`);
        }

        if (value.id == 'uc:estadual') {
          return(`\'estadual\' = any(uc_category)`);
        } else if (value.categoria == 'Unidades de Conservação - Estadual') {
        }
        return(`\'${value.name}\' = any(uc_name)`);
      })
      urlParamsFilter.push(`(${vectorParams.join(' OR ')})`);
    }

    if (filter.source && filter.source.length > 0) {
      const sourceParams = filter.source.map((value: any) => `source ILIKE \'${value}\'`);
      urlParamsFilter.push(`(${sourceParams.join(' OR ')})`);
    }
    if (filter.embargoed) {
      urlParamsFilter.push(`embargoed=\'${filter.embargoed}\'`);
    }
    if (filter.authorized) {
      urlParamsFilter.push(`authorized=\'${filter.authorized}\'`);
    }
    if (filter.validation) {
      urlParamsFilter.push(`validation=\'${filter.validation}\'`);
    }

    if (filter.size && filter.size.length) {
      const sizeParams = filter.size.map((value: any) => `size=\'${value}\'`);
      const sizeOrParams = sizeParams.join(' OR ')
      urlParamsFilter.push(`(${sizeOrParams})`);
    }
    // if (filter.size) {
    //   urlParamsFilter.push(`size=\'${filter.size}\'`);
    // }
    if (filter.satellite) {
      urlParamsFilter.push(`type_satellite=\'${filter.satellite}\'`);
    }

    urlParams.filter = urlParamsFilter.join(' AND ');
    const urlParamsStr = $.param(urlParams);

    let alertUrl = `${URL_GEO_API}/v1/mvt/${table}/{z}/{x}/{y}?${urlParamsStr}`;

    this.layers.alerts.setUrl(alertUrl);
    this.layers.alerts.redraw();


    if (!filter.ti && !filter.vector) {
      this.layers.vector.clearLayers();
      this.layers.ti.clearLayers();
      this.map.flyTo([
        -10.362318788026419,
        -63.5180291571725
      ], 8);
    }
  }
}
