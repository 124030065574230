<div class="mask" [style.backgroundImage]="imageAfter"  >
  <div class="mask_overlay" [style.backgroundImage]="imageBefore"></div>
  <button class="mask_handle ">
    <section>
      <span class="icon-angle-left">
        <img src="assets/img/arrow-left.png" alt="" sizes="" >
      </span>
      <span class="icon-angle-ball"></span>
      <span class="icon-angle-right">
        <img src="assets/img/arrow-right.png" alt="" sizes="" >
      </span>
    </section>
  </button>
</div>
<!-- <div class="c-alert-details__img"></div> -->
