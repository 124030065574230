export enum AlertClasses {
    NAO_VALIDADO = '',
    CICATRIZ_DE_QUEIMADA = 'Cicatriz de Queimada',
    CORTE_SELETIVO = 'Corte Seletivo',
    DEGRADACAO = 'Degradação',
    DESMATAMENTO = 'Desmatamento',
    DESMATAMENTO_CR = 'Desmatamento CR',
    DESMATAMENTO_VEG = 'Desmatamento VEG',
    MINERACAO = 'Mineração',
    MAPBIOMAS_ALERTA = 'Alerta',
}

export const AlertClasseColor = {
    [AlertClasses.NAO_VALIDADO]: 'magenta',
    [AlertClasses.CICATRIZ_DE_QUEIMADA]: 'yellow',
    [AlertClasses.CORTE_SELETIVO]: 'cyan',
    [AlertClasses.DEGRADACAO]: 'magenta',
    [AlertClasses.DESMATAMENTO]: 'darkred',
    [AlertClasses.DESMATAMENTO_CR]: 'orangered',
    [AlertClasses.DESMATAMENTO_VEG]: 'lawngreen',
    [AlertClasses.MINERACAO]: 'darkorange',
    [AlertClasses.MAPBIOMAS_ALERTA]: 'lightsalmon',
}

