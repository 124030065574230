<section class="c-about">
  <section class="c-about__header">
    <div class="c-about__header__text">
      <span>Sobre o projeto</span>
    </div>
    <div class="c-about__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>
  <section class="c-about__content">
    <span class="c-about__content__title"><a href=""></a>SMDK - Sistema de Monitoramento de Desmatamento Kanindé</span>
    <p>
      O Sistema de Monitoramento de Desmatamento Kanindé (SMDK), é construído baseando-se por completo no uso de
      tecnologia livre, incluindo os inputs de imagens orbitais públicas e de software livre, primordialmente Google
      Earth Engine. O sistema abrange 25 territórios indígenas, acrescido de um buffer de 10 km ao entorno de cada
      território, totalizando 6,4 milhões de hectares no estado de Rondônia.
    </p>

    <p>
      O objetivo do SMDK é realizar o monitoramento permanente de invasões e desmatamento em terras indígenas em todo o
      estado de Rondônia, contando, em algumas áreas, com o apoio de organizações indígenas que mobilizam agentes
      ambientais indígenas para fazerem um monitoramento por terra e a validação de alertas. Trata-se, portanto, de um
      sistema de monitoramento participativo, que integra sensoriamento remoto e ações de monitoramento em campo pelos
      indígenas.
    </p>

    <p>
      Os resultados desse sistema de monitoramento são utilizados não só para fazer uma divulgação mais ampla da
      situação de vulnerabilidade das terras indígenas, mas também para fundamentar denúncias a serem apresentadas aos
      órgãos competentes por parte da Kanindé e organizações indígenas aliadas e parceiras, podendo ainda ser também
      utilizado pelos órgãos públicos caso estes queiram.
    </p>

    <p>
      O SMDK é um sistema de detecção híbrida que foi projetado para produzir alertas rápidos de desmatamento e
      degradação através da integração de dados orbitais públicos, de sistemas óticos e radar, de média resolução
      espacial, fazendo o uso combinado de aprendizado de máquina (via Google Earth Engine) e validação por
      interpretação humana, sempre de maneira topologicamente assistida (por meio de regras espaciais), apoiada sobre
      dados de alta-resolução Planet (NICFI). O sistema inteiro roda sobre imagens e tecnologia de domínio público, não sendo necessário nenhum tipo de gasto
      com a aquisição de imagens de satélite, softwares ou licenças operacionais.
    </p>

    <p>
      A característica orbital do sistema é pautada na integração de dados multisensores e multitemporais, integrando
      satélites públicos de características óticas e micro-ondas. Integram o sistema os dados dos satélites Landsat 8,
      Sentinel 2A e 2B e Sentinel 1A e 2B e mosaicos mensais públicos e de alta-resolução da constelação Planet,
      utilizado para validação dos alertas detectados. Postos juntos, esse conjunto de satélites e sensores aumenta a
      capacidade de imageamento, aumentando a resolução temporal do sistema de monitoramento, sem que haja perda de
      resolução espacial. Mais além, a incorporação de imagens do espectro das microondas permitirá aquisição de
      informação mesmo em situação de grande nebulosidade, característica bastante familiar ao estado de Rondônia e a
      Amazônia Brasileira como um todo.
    </p>

    <p>
      O SMDK é dividido em 3 módulos: o <strong>Módulo de Detecção</strong>, o <strong>Módulo de Visualização/Consulta</strong> e o <strong>Módulo de Validação</strong>.
      Os módulos de <strong>Visualização/Consulta</strong> e o de <strong>Validação</strong> contam com interfaces próprias, porém interligados
      computacionalmente ao <strong>Google Earth Engine</strong>, que constitui o componente principal do <strong>Módulo de Detecção</strong>.
    </p>

    <p>
      Quanto às classes detectadas, o SMDK captura <strong>2 (duas) classes de alerta</strong>: 1) <strong>Desmatamento</strong> (Corte-Raso) e 2)
    <strong>Degradação</strong> (Desmatamentos Não-Rasos + Cicatriz de Queimadas). Toda <strong>detecção</strong> do SMDK é originalmente <strong>automática</strong>,
      havendo porém a <strong>interação e correção humana</strong> no momento de <strong>validação</strong> dos dados alertas.
    </p>

    <p>No Módulo de Validação cada alerta é humanamente verificado e validado por meio de séries temporais de satélites
      de média resolução, imagens de alta-resolução Planet (4m), em alguns locais também são realizadas verificações de
      campo pelas equipes de monitores indígenas, e colaboradores da Associação de Defesa Etnoambiental Kanindé.</p>

    <p>
      O SMDK foi concebido em parceria pelo <a href="https://www.wwf.org.br" target="_blank">WWF-Brasil</a> e a <a
        href="http://www.kaninde.org.br" target="_blank">Associação de Defesa Etnoambiental Kanindé</a>, com
      financiamento da <a href="https://www.moore.org" target="_blank">Fundação Gordon and Betty Moore</a>. O
      desenvolvimento dos mecanismos de detecção e a concepção da plataforma são criações da <a
        href="http://www.solved.eco.br" target="_blank">Solved - Soluções em Geoinformação</a> e seus colaboradores.
    </p>
  </section>
</section>
