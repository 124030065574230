import { tileLayer } from 'leaflet';
import { GEOSERVER_URL } from 'src/environments/environment';

export class BaseLayers {

    static GOOGLE = {
        Maps: {
            url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
            attribution: 'Google Maps',
            factoryLeafletTileLayer: () => tileLayer('https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}', {
                attribution: 'Google Maps'
            })
        },
        Satellite: {
            url: 'https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
            attribution: ' Google Satellite',
            factoryLeafletTileLayer: () => tileLayer('https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}', {
                attribution: ' Google Satellite'
            })
        },
        SatelliteHybrid: {
            url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
            attribution: 'Google Satellite Hybrid',
            factoryLeafletTileLayer: () => tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
                attribution: 'Google Satellite Hybrid'
            })

        },
        Terrain: {
            url: 'https://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}',
            attribution: 'Google Terrain',
            factoryLeafletTileLayer: () => tileLayer('https://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}', {
                attribution: 'Google Terrain'
            })
        },
        Roads: {
            url: 'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}',
            attribution: 'Google Roads',
            factoryLeafletTileLayer: () => tileLayer('https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}', {
                attribution: 'Google Roads'
            })
        }
    }

    static OSM = {
        Standard: {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: 'Open Street Maps',
            factoryLeafletTileLayer: () => tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Open Street Maps',
            })
        },
        Monochrome: {
            url: 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
            attribution: 'Open Street Maps',
            factoryLeafletTileLayer: () => tileLayer('http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', {
                attribution: 'Open Street Maps',
            })
        }
    }
    
    static CARTO = {
        Dark: {
            url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',            
            attribution: 'Open Street Maps',
            factoryLeafletTileLayer: () => tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
                attribution: 'Carto',
            })
        }
    }


    static Reference = {
        Estados: {
            url: `${GEOSERVER_URL}/kaninde/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kaninde/wms`, {
                attribution: '',
                layers: 'kaninde:states',
                format: 'image/png',
                transparent: true,
            })
        },
        Municipios: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: 'IBGE',
                layers: 'kanindeRO:geo_municipios',
                format: 'image/png',
                transparent: true,
            })
        },
        Biomas: {
            url: `${GEOSERVER_URL}/kaninde/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kaninde/wms`, {
                attribution: 'IBGE',
                layers: 'CGMAT:qg_2017_240_bioma',
                format: 'image/png',
                transparent: true,
            })
        },
        AmazoniaLegal: {
            url: `${GEOSERVER_URL}/kaninde/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kaninde/wms`, {
                attribution: 'IBGE',
                layers: 'CGMAT:qg_2019_040_amazleg',
                format: 'image/png',
                transparent: true,
            })
        },
        Quilombos: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: 'IBGE',
                layers: 'kanindeRO:geo_quilombos',
                format: 'image/png',
                transparent: true,
            })
        },
        CAR: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_car',
                format: 'image/png',
                transparent: true,
            })
        },
        PRODES: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_prodes',
                format: 'image/png',
                transparent: true,
            })
        },
        MalhaFundiaria: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kaninde:MalhaFundiaria_Rondônia',
                format: 'image/png',
                transparent: true,
            })
        },
        UC: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_uc',
                format: 'image/png',
                transparent: true,
            })
        },
        TI: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_ti',
                format: 'image/png',
                transparent: true,
            })
        },
        TIb3: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:view_ti_buf3',
                format: 'image/png',
                transparent: true,
            })
        },
        TIb5: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:view_ti_buf5',
                format: 'image/png',
                transparent: true,
            })
        },
        TIb10: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:view_ti_buf10',
                format: 'image/png',
                transparent: true,
            })
        },
        ASSENTAMENTOS: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_assentamentos',
                format: 'image/png',
                transparent: true,
            })
        },
        EMBARGOS: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_embargos',
                format: 'image/png',
                transparent: true,
            })
        },
        AUTORIZACOES: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_asv',
                format: 'image/png',
                transparent: true,
            })
        },
        RODOVIAS: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:estradas',
                format: 'image/png',
                transparent: true,
            })
        },
        ETNOZONEAMENTO: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:etnozoneamento',
                format: 'image/png',
                transparent: true,
            })
        },
        ALDEIAS: {
            url: `${GEOSERVER_URL}/kanindeRO/wms`,
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: '',
                layers: 'kanindeRO:geo_aldeias',
                format: 'image/png',
                transparent: true,
            })
        },
        SRTM: {
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                layers:'kanindeRO:RO_srtm_v1',
                format: 'image/png',
                transparent: true,
            } as any)
        },
        ANA: {
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                layers: 'kanindeRO:geo_ana',
                format: 'image/png8',
                transparent: true,
            } as any)
        },
        FIRMS: {
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                layers: 'kanindeRO:ext_geo_firms_7d',
                transparent: true,
                format: 'image/png8',
            } as any)
        },
        PISTAS: {
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                layers: 'kanindeRO:geo_pista_pouso',
                transparent: true,
                format: 'image/png8',
            } as any)
        }
    }

    static MapBiomas = {
        Classification: {
            factoryLeafletTileLayer: () => tileLayer.wms(`${GEOSERVER_URL}/kanindeRO/wms`, {
                attribution: 'MapBiomas Classification',
                layers: 'kanindeRO:MB6_2020',
                format: 'image/png',
                transparent: true,
            } as any)
        }
    };

    // static SRTM = {
    //     Mosaic: {
    //         factoryLeafletTileLayer: () => tileLayer.wms('https://ows.terrestris.de/osm/service?styles=&service=WMS&sld_version=1.1.0&version=1.1.1&request=GetMap', {
    //             layers: 'SRTM30-Colored-Hillshade',
    //             format: 'image/png',
    //             transparent: true,
    //         } as any)
    //     }
    // };

    static Planet = {
        Mosaic: {
            url: 'https://tiles0.planet.com/basemaps/v1/planet-tiles/planet_medres_normalized_analytic_2021-05_mosaic/gmap/{z}/{x}/{y}.png?api_key=PLAK8a4526b06ccb417790894b91bd8816d5',
            factoryLeafletTileLayer: () => tileLayer('https://tiles0.planet.com/basemaps/v1/planet-tiles/planet_medres_normalized_analytic_2021-05_mosaic/gmap/{z}/{x}/{y}.png?api_key=PLAK8a4526b06ccb417790894b91bd8816d5', {
                attribution: 'Planet Mosaic',
            })
        }
    };
}
