import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-dialog',
    templateUrl: 'dialog.component.html',
    styles: [`
         ::ng-deep #app-dialog{            
            overflow-y: hidden !important;
        }
    `]
})
export class DialogComponent {
   
    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string,
            description: string,
            cancel: string | false;
            confirm: string;
            value?: any
        }
    ) { }
}