import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './users-routing.module';
import { UserLayoutComponent } from 'src/app/layouts/user-layout/user-layout.component';
import { ListComponent } from './list/list.component';
import { EmailComponent } from './email/email.component';
import { PasswordComponent } from './password/password.component';
import { RecoverPasswordComponent } from './recoverpassword/recoverpassword.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddComponent } from './add/add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoginDialogComponent } from './login/login-dialog.component';
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    UserRoutingModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule
  ],
  declarations: [
    UserLayoutComponent,
    ListComponent,
    EmailComponent,
    RecoverPasswordComponent,
    PasswordComponent,
    AddComponent,
    LoginDialogComponent
  ],
  exports: []
})
export class UsersModule { }
