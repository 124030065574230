import { UploadsModule } from './pages/uploads/uploads.module';
import { UploadsRoutingModule } from './pages/uploads/uploads-routing.module';
import { UploadsPageComponent } from './pages/uploads/uploads-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthGuard, AuthGuard } from './auth.guard';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { MapLayoutComponent } from './layouts/map-layout/map-layout.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { UploadLayoutComponent } from './layouts/upload-layout/upload-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/alertas/visualizacao',
    pathMatch: 'full'
  },
  {
    path: 'home',
    redirectTo: '/alertas/visualizacao',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MapLayoutComponent,
    // canActivate: AuthGuard,
    children: [
      {
        path: 'alertas',
        children: [
          {
            path: 'visualizacao',
            loadChildren: () => import('./pages/alerts/visualization/alerts-visualization-page.module').then(m => m.AlertsVisualizationPageModule)
          },
          {
            path: 'validacao',
            loadChildren: () => import('./pages/alerts/validation/alerts-validation-page.module').then(m => m.AlertsValidationPageModule)
          },
          // {
          //   path: 'upload',
          //   loadChildren: () => import('./pages/uploads/uploads.module').then(m => m.UploadsModule)
          // }
        ]
      }
    ]
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      },
    ],
  },
  {
    path: 'users',
    component: UserLayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AdminAuthGuard],
        loadChildren:()=> import('./../app/modules/users/users.module').then(m => m.UsersModule)
      },

    ],
  },
  {
    path: 'upload',
    component: UploadLayoutComponent,
    loadChildren: () => import('./pages/uploads/uploads.module').then(m => m.UploadsModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
