import { Component, OnInit, Inject,  Renderer2, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.scss']
})
export class SubtitlesComponent implements OnInit {
  @ViewChild('subtitlePanel') subtitlePanel: ElementRef;
  @Input() statusPanel : boolean;
  @Output() openLayerEvent = new EventEmitter<boolean>();
  el: ElementRef;

  subtitles = [];
  color = '#008837';
  checked = false;
  disabled = false;

  constructor(private renderer: Renderer2,el: ElementRef) {this.el = el;}

  ngOnInit(): void {
    this.subtitles = [
      {
        title: 'Cicatriz de Queimada',
        source:'(DETERB)',
        color: '#FFFF00'
      },
      {
        title: 'Corte Seletivo',
        source:'(DETERB)',
        color: '#92F4F2'
      },
      {
        title: 'Degradação',
        source:'(SMDK, DETERB, SAD)',
        color: '#FF00FF'
      },
      {
        title: 'Desmatamento',
        source:'(SMDK, DETERB, SAD, Mapbiomas)',
        color: '#8B0000'
      },
      // {
      //   title: 'Desmatamento CR (DETERB)',
      //   color: '#FF4500'
      // },
      // {
      //   title: 'Desmatamento VEG (DETERB)',
      //   color: '#7CFC00'
      // },
      // {
      //   title: 'Mapbiomas Alerta',
      //   color: '#FFA07A'
      // },
      // {
      //   title: 'Mineração (DETERB)',
      //   color: '#FF8C00'
      // }
    ]
  }

  close() {
    this.openLayerEvent.emit(true);
  }

  ngAfterViewInit() {
    const hostElem = this.el.nativeElement;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.statusPanel == true && !hostElem.parentNode.contains(e.target) && !this.subtitlePanel.nativeElement.contains(e.target)) {
        this.close();
      }
    });
  }
}
