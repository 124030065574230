import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService, LoginData } from 'src/app/services/auth.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  loginData: LoginData = {} as LoginData;

  modalComponents: {};

  showLoginError = false;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: []
  ) { }

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit() {
    let auth = await this.authService.login(this.loginData).catch(() => {
      this.showLoginError = true;
      setTimeout(() => {
        this.showLoginError = false;
      }, 3000);
    });
    if (auth) {
      this.dialogRef.close();
    }

  }
}
