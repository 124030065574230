<mat-sidenav-container class="sidenav-container">
  <mat-sidenav mode="over" #sidenav class="sidenav">
    <div class="sidenav-menu" fxLayout="column">
      <div class="menu-header">
        <div class="logo">
          <img src="\assets\img\kaninde\SMDK+font.svg" />
        </div>
        <hr class="header-separator"/>
      </div>
      <div class="menu-main" fxFlex="auto">
        <mat-nav-list c>
          <a mat-list-item [routerLink]="['/alertas/visualizacao']" routerLinkActive="router-link-active">
            <div class="menu-item">
              <img class="menu-icon" src="/assets/img/icon-menu/iconMenu_grayscale/consulta_grayscale.svg" />
              <img class="menu-icon-active"
                src="/assets/img/icon-menu/iconMenu_colorHovering/consulta_colorHovering.svg" />
              <p>Consulta</p>
            </div>
          </a>
          <ng-container *ngIf="authorization.user.security_level == 2">
            <!-- <a mat-list-item [href]="URL_VALIDATION_MODULE + '?token=' + authorization.token" routerLinkActive="router-link-active"> -->
            <a mat-list-item [routerLink]="['/alertas/validacao']" routerLinkActive="router-link-active">
              <div class="menu-item">
                <img class="menu-icon" src="/assets/img/icon-menu/iconMenu_grayscale/validacao_grayscale.svg" />
                <img class="menu-icon-active"
                  src="/assets/img/icon-menu/iconMenu_colorHovering/validacao_colorHovering.svg" />
                <p>Validação</p>
              </div>
            </a>
          </ng-container>
          <ng-container *ngIf="authorization.user.security_level == 2">
            <a mat-list-item [routerLink]="['/upload']" routerLinkActive="router-link-active">
              <div class="menu-item">
                <img class="menu-icon" src="/assets/img/icon-menu/iconMenu_grayscale/upload_grayscale.svg" />
                <img class="menu-icon-active"
                  src="/assets/img/icon-menu/iconMenu_colorHovering/upload_colorHovering.svg" />
                <p>Upload</p>
              </div>
            </a>
          </ng-container>
          <ng-container *ngIf="authorization.user.security_level == 2">
            <a mat-list-item [href]="URL_DASHBOARD + '?token=' + authorization.token" routerLinkActive="router-link-active">
              <div class="menu-item">
                <img class="menu-icon" src="/assets/img/icon-menu/iconMenu_grayscale/dashboard_grayscale.svg" />
                <img class="menu-icon-active"
                  src="/assets/img/icon-menu/iconMenu_colorHovering/dashboard_colorHovering.svg" />
                <p>Dashboard</p>
              </div>
            </a>
          </ng-container>
        </mat-nav-list>
      </div>
      <div class="menu-botton">
        <hr class="bottom-separator">
        <mat-nav-list [disableRipple]="true">
          <ng-container  *ngIf="authorization.user.security_level == 2">
            <a mat-list-item [routerLink]="['/users']" routerLinkActive="router-link-active">
              <div class="menu-item">
                <img class="menu-icon" src="/assets/img/icon-menu/iconMenu_grayscale/admin_grayscale.svg" />
                <img class="menu-icon-active"
                  src="/assets/img/icon-menu/iconMenu_colorHovering/admin_colorHovering.svg" />
                <p>Administração</p>
              </div>
            </a>
          </ng-container>
            <a mat-list-item
            (click)="openDialog('AboutComponent', 598)"
            style="position: absolute;
                      bottom: 0;"
            >
            <div class="menu-item">
              <img class="menu-icon" src="/assets/img/icon-menu/iconMenu_grayscale/info_grayscale.svg" />
              <img class="menu-icon-active"
              src="/assets/img/icon-menu/iconMenu_colorHovering/info_colorHovering.svg" />
              <p>Sobre o projeto</p>
            </div>
          </a>
        </mat-nav-list>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
