<section class="c-overlayer" #overLayerPanel>
  <section class="c-overlayer__header">
    <div class="c-overlayer__header__text">
      <span>Camadas</span>
    </div>
  </section>
  <section class="c-overlayer__content">
    <div class="c-baselayer__list">
      <ng-container *ngIf="scrHeight > 860">
        <mat-accordion class="example-headers-align" multi="false">
          <ng-container *ngFor="let overlay of overlayers">
            <div class="colum-2">
              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-slide-toggle class="example-margin" (click)="$event.stopPropagation();"
                    [(ngModel)]="referenceLayers[overlay.layerKey]" (change)="layerSelected($event)" [color]="color"
                    [checked]="checked" [disabled]="disabled">
                    {{overlay.camada}}
                  </mat-slide-toggle>
                </mat-expansion-panel-header>
                <a [href]="overlay.url" target="_blank" style="color: dimgray;">
                  <span class="panel-text">Fonte: {{overlay.fonte}} </span>
                </a>
                <span class="panel-text">Ano: {{overlay.ano}}</span>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </mat-accordion>
      </ng-container>
      <ng-container *ngIf="scrHeight <= 860">
        <ng-container  *ngFor="let columnPanels of columns" >
          
          <mat-accordion class="example-headers-align" multi="false">
            <ng-container *ngFor="let overlay of columnPanels">
              <div class="colum-2">
                <mat-expansion-panel class="mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-slide-toggle class="example-margin" (click)="$event.stopPropagation();"
                      [(ngModel)]="referenceLayers[overlay.layerKey]" (change)="layerSelected($event)" [color]="color"
                      [checked]="checked" [disabled]="disabled">
                      {{overlay.camada}}
                    </mat-slide-toggle>
                  </mat-expansion-panel-header>
                  <a [href]="overlay.url" target="_blank" style="color: dimgray;">
                    <span class="panel-text">Fonte: {{overlay.fonte}} </span>
                  </a>
                  <span class="panel-text">Ano: {{overlay.ano}}</span>
                </mat-expansion-panel>
              </div>
            </ng-container>
          </mat-accordion>

        </ng-container>
      </ng-container>

      <!-- <mat-accordion class="example-headers-align" multi="false">
        <ng-container *ngFor="let overlay of overlayers">
          <div class="colum-2">
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-slide-toggle class="example-margin" (click)="$event.stopPropagation();"
                  [(ngModel)]="referenceLayers[overlay.layerKey]" (change)="layerSelected($event)" [color]="color"
                  [checked]="checked" [disabled]="disabled">
                  {{overlay.camada}}
                </mat-slide-toggle>
              </mat-expansion-panel-header>
              <a [href]="overlay.url" target="_blank" style="color: dimgray;">
                <span class="panel-text">Fonte: {{overlay.fonte}} </span>
              </a>
              <span class="panel-text">Ano: {{overlay.ano}}</span>
            </mat-expansion-panel>
          </div>
        </ng-container>
      </mat-accordion> -->

      <!-- <ng-container *ngFor="let overlay of overlayers">
        <div class="colum-2">
          <mat-slide-toggle class="example-margin" [(ngModel)]="referenceLayers[overlay.layerKey]"
            (change)="layerSelected($event)" [color]="color" [checked]="checked" [disabled]="disabled">
            {{overlay.camada}}
          </mat-slide-toggle>
          <img class="icon-default" src="../../../../assets/img/arrow-down.svg" *ngIf="!show[overlay.layerKey]" alt=""
            (click)="openAccordion(overlay.layerKey)">
          <img class="icon-default" src="../../../../assets/img/chevron-down.svg" *ngIf="show[overlay.layerKey]" alt=""
            (click)="openAccordion(overlay.layerKey)">
        </div>
        <div class="panel" *ngIf="show[overlay.layerKey]">
          <a [href]="overlay.url" target="_blank" style="color: dimgray;">
            <span class="panel-text">Fonte: {{overlay.fonte}} </span>
          </a>
          <span class="panel-text">Ano: {{overlay.ano}}</span>
        </div>
      </ng-container> -->
    </div>
  </section>
</section>