<header class="c-header">
  <div class="c-header-main">
    <section class="c-header-main__logo">
      <!-- <img src="assets/img/kaninde/logo.png" alt=""> -->
      <img src="assets/img/kaninde/SMDK+font.svg" alt="">
    </section>
    <section class="c-header-main__content">
      <!-- <span class="content-title"> Sistema de Monitoramento de Desmatamento Kanindé - SMDK</span>
      <section class="c-header__icons">
        <span (click)="openDialog('AboutComponent', 598)"> <img src="assets/img/info.svg" alt=""></span>
        <span (click)="openDialog('ShareComponent', 678)"> <img src="assets/img/share.svg" alt=""></span>
      </section> -->
    </section>
  </div>
</header>
