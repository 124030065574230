<div style="padding: 15px">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content class="mat-typography" style="width: 500px">
    <p [innerHTML]="data.description"></p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      *ngIf="data.cancel"
      mat-button
      [mat-dialog-close]="false"
      color="warn"
      cdkFocusInitial
    >
      {{ data.cancel }}
    </button>
    <button mat-button [mat-dialog-close]="true" color="primary">
      {{ data.confirm }}
    </button>
  </mat-dialog-actions>
</div>
