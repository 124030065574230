import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { AuthEvents, AuthService } from '../auth.service';
import { catchError } from 'rxjs/operators';
import { GEOSERVER_URL } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const excludedUrls = [
          'https://plataforma.brasil.mapbiomas.org/downloads/history_point',
          `${GEOSERVER_URL}/wfs`,
        ];

        if (excludedUrls.some(url => req.url.includes(url))) {
          return next.handle(req);
        }

        let authReq = req;

        const authorization = this.authService.getAuthorization();

        if(authorization){
            authReq = req.clone({ setHeaders: { 'Authorization': `Bearer ${authorization.token}` } });
        }

        let handle = next.handle(authReq).pipe(
            catchError(err => {
                if (err.status == 401) {
                    AuthEvents.onUnauthorized.emit();
                    localStorage.removeItem('authorization');
                    throw "Unauthorized";
                }
                throw `Error ${err.status}`;
            })
        );

        return handle;
    }
}
