import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Alert } from 'src/app/services/entities/alert';
import { URL_ASSETS } from 'src/environments/environment';

@Component({
  selector: 'app-images-swap-slider',
  templateUrl: './images-swap-slider.component.html',
  styleUrls: ['./images-swap-slider.component.scss']
})
export class ImagesSwapSliderComponent implements OnInit, OnChanges {

  @Input()
  alert: Alert = {} as Alert;

  imageBefore: string;

  imageAfter: string;

  imgUndefined:boolean = false

  constructor() { }

  ngOnInit(): void {
    $('.mask_overlay').css('width', $('.mask_handle').css('left'));
    $(".mask_handle").click(function (e) {
      e.preventDefault();
    });
    $('.mask_handle').draggable({
      addClasses: true,
      cancel: false,
      classes: {
        "ui-draggable": "highlight"
      },
      containment: "parent",
      axis: "x",
      drag: function (event, ui) {
        let x_position = $(this).css('left');
        let new_x_position = x_position.replace('px', '');
        x_position = parseInt(new_x_position) + 28;
        $('.mask_overlay').css('width', x_position);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.alert) {
      if (this.alert.source == "Mapbiomas") {
        this.imageBefore = `url(https://storage.googleapis.com/alerta-public/IMAGES/${this.alert.original_id}/before_deforestation.png)`
        this.imageAfter  = `url(https://storage.googleapis.com/alerta-public/IMAGES/${this.alert.original_id}/after_deforestation.png)`
      } else if (this.alert.source != "SMDK") {
        this.imageBefore = `url(${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_before.split(',')[0]}_antes_poly.png)`
        this.imageAfter  = `url(${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_after.split(',')[0]}_depois_poly.png)`
      } else {
        this.imageBefore = `url(${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_before.split(',')[0]}_poly.png)`
        this.imageAfter  = `url(${URL_ASSETS}/${this.alert.id}_${this.alert.image_id_after.split(',')[0]}_poly.png)`
      }
    }
  }
}
