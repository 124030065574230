import { AppModule } from './../../../app.module';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '../about/about.component';
import { ShareComponent } from '../share/share.component';
import { OnAppModuleInit } from 'src/app/app.component';
import { AuthService } from 'src/app/services/auth.service';
import { Authorization } from 'src/app/services/authorization';
import { LoadingService } from 'src/app/services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-header-upload',
  templateUrl: './header-upload.component.html',
  styleUrls: ['../header/header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderUploadComponent implements OnInit {
  modalComponents: {};
  isLoading: boolean = false;

  appModules: Record <string, any> = {
    "MODULE_ALERTS_VISUALIZATION": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/consulta_colorHovering.svg",
      title: "Módulo de Consulta e Visualização"
    },
    "MODULE_ALERTS_VALIDATION": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/validacao_colorHovering.svg",
      title: "Módulo de Edição e Validação",
    },
    "MODULE_ALERTS_UPLOAD": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/upload_colorHovering.svg",
      title: "Módulo de Upload",
    },
    "MODULE_ALERTS_DASHBOARD": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/dashboard_colorHovering.svg",
      title: "Módulo de Dashboard",
    },
  };

  appModule: any;
  authorization: Authorization;

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public loadingService: LoadingService,
    public cdr: ChangeDetectorRef,
    public router: Router,
  ) {}

  async ngOnInit() {
    this.authorization = this.authService.getAuthorization();
    this.appModule = this.appModules["MODULE_ALERTS_UPLOAD"];

    this.loadingService.isLoading.subscribe(isLoading => {
      this.isLoading = isLoading;
      this.cdr.detectChanges();
    })

    OnAppModuleInit.subscribe(mod => {
      this.appModule = this.appModules[mod];
    });
  }

  openDialog(component, width): void {
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`
    });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
