<header class="l-header">
  <div class="l-header-main">
    <section class="l-header-main__logo">
      <!-- <img src="assets/img/kaninde/logo.png" alt=""> -->
      <img src="assets/img/kaninde/SMDK+font.svg" alt="">
    </section>
    <section class="l-header-main__content">
      <div id="progress-spinner">
        <mat-progress-spinner diameter=30 mode="indeterminate" color="accent" *ngIf="isLoading">
        </mat-progress-spinner>
      </div>
        <!-- icon and header text -->
        <div class="position-center">
          <div class="headerbox-bg">
            <div class="headerbox">
              <span class="content-title" id="grid-mod-header">
                <img
                  id="icon-module"
                  [src]="appModule.logo"
                  [alt]="appModule.title"
                />
                <!-- <img src="/assets/img/icon-menu/iconMenu_colorHovering/consulta_colorHovering.svg"
                  alt="Módulo de Consulta" id="icon-module"> -->
                <span class="item-linebox"></span>
                <span class="title-header">{{appModule.title}}</span>
                <!-- <span class="title-header">Módulo</span> -->
              </span>
            </div>
          </div>
        </div>
        <span>
          <ngx-avatar class="my-avatar user-avatar" [matMenuTriggerFor]="menu" [name]="authorization.user.name"
            size="40"></ngx-avatar>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="openDialog('EmailComponent', 450)"><span class="title-btn">Alterar
                e-mail</span></button>
            <button mat-menu-item (click)="openDialog('PasswordComponent', 450)"><span class="title-btn">Alterar
                senha</span></button>
            <button mat-menu-item (click)="logout()"><span class="title-btn">Sair</span></button>
          </mat-menu>
        </span>
    </section>
  </div>
</header>
